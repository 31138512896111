import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import CurrencyInput from 'react-currency-input';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/pt-br';

export default class Input extends Component {

    constructor() {
        super();
        this.state = {
            inputText: ''
        };
    }

    getFile(e) {
        this.setState({ inputText: e[0] ? e[0].name : '' });
    }

    renderInput() {
        const { textHelper, type, label, grow, optionsData, pegaArquivo, multiFiles, mascara, divStyle, textEditor, ...otherProps } = this.props;
        if (type === "select") {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', flexGrow: !grow && grow !== 0 ? 1 : grow, ...divStyle }}>
                    {label ? (
                        <label>{label}</label>
                    ) : null}
                    <select {...otherProps}>
                        {optionsData.map((item) => {
                            return (
                                <option key={item.value} value={item.value}>{item.text}</option>
                            );
                        })}
                    </select>
                    {textHelper ? (
                        <p style={{ marginTop: -20, marginBottom: 23, color: '#EA5353' }}>* {textHelper}</p>
                    ) : null}
                </div>
            );
        } else if (type === "file") {
            const { inputText } = this.state;
            return (
                <div style={{ display: 'flex', flexDirection: 'column', flexGrow: !grow && grow !== 0 ? 1 : grow }}>
                    {label ? (
                        <label>{label}</label>
                    ) : null}
                    <input type="file" id="selectedFile" onChange={(e) => {
                        this.getFile(e.target.files);
                        pegaArquivo(e.target.files);
                    }} {...otherProps} />
                    <button type="button" onClick={() => document.querySelector(multiFiles ? multiFiles : '#selectedFile').click()} className="btnInput"
                        style={{ minWidth: 460, height: 50, background: '#FFF', borderRadius: 5, padding: '2px 3px', border: '1px solid #DFDFDF' }} >
                        <span style={{ fontSize: 16, lineHeight: '46px', paddingLeft: 10, paddingRight: 10 }}>{inputText}</span>
                        <span style={{ float: 'right', background: '#DDD', padding: '8px 11.3px', borderRadius: 5, fontSize: 24 }}>...</span>
                    </button>
                </div>
            )
        } else if (type === "checkbox") {
            return (
                <>
                    <div style={{ display: 'flex', flexDirection: 'column', flexGrow: grow ? grow : 1 }}>
                        <label style={{ display: 'flex', alignItems: 'center', fontSize: 14, color: '#4F4F4F' }}>
                            <input type="checkbox" style={{ marginRight: 9, marginLeft: 0, /* width: 17, height: 17 */ }} {...otherProps} /> {label}
                        </label>
                        {textHelper ? (
                            <p style={{ marginTop: -20, marginBottom: 23, color: '#EA5353' }}>* {textHelper}</p>
                        ) : null}
                    </div>
                </>
            )
        } else if (type === "mask") {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', flexGrow: !grow && grow !== 0 ? 1 : grow }}>
                    {label ? (
                        <label style={{ color: label === "a" ? 'transparent' : '#4F4F4F' }}>{label}</label>
                    ) : null}
                    <InputMask mask={mascara} {...otherProps} />
                </div>
            )
        } else if (type === "money") {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', flexGrow: !grow && grow !== 0 ? 1 : grow, ...divStyle }}>
                    {label ? (
                        <label style={{ color: label === "a" ? 'transparent' : '#4F4F4F' }}>{label}</label>
                    ) : null}
                    <CurrencyInput decimalSeparator="," thousandSeparator="." {...otherProps} />
                    {textHelper ? (
                        <p style={{ marginTop: -20, marginBottom: 23, color: '#EA5353' }}>* {textHelper}</p>
                    ) : null}
                </div>
            );
        } else if (type === "textarea") {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', flexGrow: !grow && grow !== 0 ? 1 : grow, ...divStyle }}>
                    {label ? (
                        <label style={{ color: label === "a" ? 'transparent' : '#4F4F4F', fontSize: 14, marginBottom: 3 }}>{label}</label>
                    ) : null}
                    {textEditor ? (
                        <CKEditor
                            config={{
                                language: 'pt-br'
                            }}
                            editor={ClassicEditor}
                            data={otherProps.value}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                otherProps.changeEditor(data, otherProps.attr, 'editor');
                            }}
                        />
                    ) : (
                            <textarea {...otherProps} />
                        )}
                    {textHelper ? (
                        <p style={{ marginTop: -20, marginBottom: 23, color: '#EA5353' }}>* {textHelper}</p>
                    ) : null}
                </div>
            );
        } else {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', flexGrow: !grow && grow !== 0 ? 1 : grow, ...divStyle }}>
                    {label ? (
                        <label style={{ color: label === "a" ? 'transparent' : '#4F4F4F' }}>{label}</label>
                    ) : null}
                    <input type={type} {...otherProps} />
                    {textHelper ? (
                        <p style={{ marginTop: -20, marginBottom: 23, color: '#EA5353' }}>* {textHelper}</p>
                    ) : null}
                </div>
            );
        }
    }

    render() {
        return (
            <>
                {this.renderInput()}
            </>
        );
    }
}