import React, { Component, Fragment } from 'react';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Input from '../../Components/Input';
import Table from '../../Components/Table';
import Paginacao from '../../Components/Paginacao';
import Funcoes from '../../Utils/functions';
import ApiService from '../../Utils/services';
import { cpfMask, cnpjMask } from '../../Utils/mask';

export default class Charges extends Component {

    constructor(props) {
        super(props);

        this.acao = this.acao.bind(this);
        this.handlePage = this.handlePage.bind(this);
        this.state = {
            redirect: false,
            path: '',
            propsToPath: null,
            loading: false,
            alert: false,
            alertText: '',
            filtroOptions: [
                { value: 'cpf', text: 'CPF/CNPJ' },
                { value: 'referencia', text: 'Cód. Referência' }
            ],
            valorFiltro: props.location.state ? props.location.state.valorFiltro : 'cpf',
            filtro: '', //743.605.790-12
            table: {
                campos: [
                    { titulo: 'Cód. Referência', dado: 'codRef' },
                    { titulo: 'Empresa', dado: 'empresa' },
                    { titulo: 'Cliente', dado: 'cliente' },
                    { titulo: 'CPF/CNPJ', dado: 'cpfCnpj' },
                    { titulo: 'Data Vencimento', dado: 'dtVencimento', thStyle: { textAlign: 'center' }, tdStyle: { textAlign: 'center' } },
                    { titulo: 'Valor', dado: 'valLiq', thStyle: { textAlign: 'right' }, tdStyle: { textAlign: 'right' } },
                    { titulo: '', dado: 'acoes' }
                ],
                dados: []
            },
            cobrancaDefault: {},
            mostraVazio: false,
            exibeDados: false,
            pagina: 1
        };
    }

    componentDidMount() {
        if (this.props.location.state) {
            const props = this.props.location.state;
            this.setState({ pagina: props.pagina, valorFiltro: props.valorFiltro, filtro: props.filtro, path: '/consultar-cobranca?page=' + props.pagina, redirect: true });
            this.listaCobrancas(props.pagina, props.filtro);
        }
    }

    async listaCobrancas(page, valFiltro) {
        const { table, valorFiltro, filtro } = this.state;
        const f = valFiltro ? valFiltro : filtro;
        const filter = f ? f.trim() : '';
        if (filter) {
            var url, pagina, pagAtual;
            const userData = JSON.parse(sessionStorage.getItem('userData'));
            this.setState({ loading: true });

            pagina = Funcoes.getAllUrlParams(window.location.href);
            pagina = pagina.page ? pagina.page : 1;
            pagAtual = page ? page : pagina;

            url = "cobranca?per_page=20";
            if (valorFiltro === "cpf") url = url + "&cli_cpf=" + filter;
            if (valorFiltro !== "cpf") url = url + "&ecob_codreferencia=" + filter;
            if (pagAtual) url = url + "&page=" + pagAtual;

            await ApiService.getBackoffice(url, userData.token)
                .then(result => {
                    const res = result.data.data;
                    var cobrancas = [], valorSoma = 0;
                    for (var i = 0; i < res.length; i++) {
                        const item = res[i];
                        const valor = item.ecob_valor ? item.ecob_valor : 0;
                        cobrancas.push({
                            id: item.ecob_id, codRef: item.ecob_codreferencia, empresa: item.empresa[0] ? item.empresa[0].emp_nomefantasia : '', cliente: item.ecob_nomerazao,
                            cpfCnpj: item.ecob_cpfcnpj, dtVencimento: Funcoes.data2Brazilian(item.ecob_dtvencto, '-'), valLiq: 'R$ ' + Funcoes.float2Preco(valor), acoes: 'editar'
                        });
                        valorSoma += valor;
                    }
                    cobrancas.push({ codRef: 'Total Listado:', valLiq: 'R$ ' + Funcoes.float2Preco(valorSoma), acoes: '' });
                    this.setState({
                        table: { campos: [...table.campos], dados: cobrancas }, exibeDados: res.length > 0 ? true : false,
                        mostraVazio: res.length > 0 ? false : true, cobrancaDefault: result.data, loading: false
                    });
                })
                .catch(err => {
                    const valida = ApiService.handleErros(err.response);
                    if (valida.status === "unauthorized") {
                        sessionStorage.removeItem('userData');
                        this.setState({ path: '/' });
                    }
                    if (valida.status === "serverError") { this.setState({ path: '' }); }
                    if (valida.status === "badRequest") { this.setState({ path: '' }); }

                    this.setState({ loading: false, alertText: valida.response, alert: true });
                })
        } else {
            this.setState({ alertText: 'O filtro não pode ficar vazio!', alert: true });
        }
    }

    async acao(id) {
        const { pagina, valorFiltro, filtro } = this.state;
        this.setState({ loading: true });
        const userData = JSON.parse(sessionStorage.getItem('userData'));
        await ApiService.getBackoffice('cobranca/' + id, userData.token)
            .then(res => {
                this.setState({
                    path: '/consultar-cobranca/detalhes', propsToPath: {
                        cobranca: { ...res.data.data, id },
                        paginacao: { pagina, valorFiltro, filtro }
                    }, loading: false, redirect: true
                });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") { this.setState({ path: '' }); }
                if (valida.status === "badRequest") { this.setState({ path: '' }); }

                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    handleFiltro(value) {
        const { valorFiltro } = this.state;
        if (valorFiltro === "cpf") {
            this.setState({ filtro: value.length <= 14 ? cpfMask(value) : cnpjMask(value) });
        } else {
            this.setState({ filtro: value });
        }
    }

    handlePage(page) {
        if (page) {
            this.setState({ path: '/consultar-cobranca?page=' + page, pagina: page, redirect: true });
        } else {
            this.setState({ path: '/consultar-cobranca', pagina: 1, redirect: true });
        }
        this.listaCobrancas(page);
    }

    handleSubmit(event) {
        if (event.key === "Enter") {
            this.listaCobrancas();
        }
    }

    render() {
        const { redirect, path, propsToPath, loading, alert, alertText, table, filtroOptions, valorFiltro, filtro, exibeDados, mostraVazio, cobrancaDefault } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} path={path} props={propsToPath} />
                <Loading show={loading} />
                <Alert show={alert} texto={alertText} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <SideMenu menuAtivo={9} />

                <div className="body">
                    <Header />

                    <div className="cobrancas">
                        <div className="tituloButton">
                            <div>
                                <h1>Consultar Cobrança</h1>
                                <p className="descricao">Abaixo listamos todas cobranças conforme filtro aplicado.</p>
                            </div>
                        </div>

                        <div className="filtros">
                            <div className="panel">
                                <Input type="select" label="Buscar por" grow={0} optionsData={filtroOptions} style={{ minWidth: 185, marginRight: 20 }}
                                    value={valorFiltro} onChange={e => this.setState({ valorFiltro: e.target.value, filtro: '' })} />
                                <Input type="tel" grow={0} value={filtro} onChange={e => this.handleFiltro(e.target.value)} onKeyPress={e => this.handleSubmit(e)}
                                    style={{ marginTop: 18, width: 230, marginRight: 31 }} maxLength={18} />
                                <button className="btn bg-roxo cl-branco" onClick={() => this.listaCobrancas()}>Filtrar</button>
                            </div>
                        </div>

                        <div className="panel pagto">
                            {exibeDados ? (
                                <Table campos={table.campos}
                                    dados={table.dados}
                                    acaoEditar={this.acao} />
                            ) : null}
                            {mostraVazio ? (
                                <p style={{ color: '#ABABAB', fontSize: 20 }}>Nenhum resultado foi encontrado!</p>
                            ) : null}
                        </div>

                        <Paginacao dados={cobrancaDefault} handlePage={this.handlePage} />
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}