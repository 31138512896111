import React, { Component, Fragment } from 'react';
import $ from "jquery";
// import { CopyToClipboard } from 'react-copy-to-clipboard';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Input from '../../Components/Input';
import Table from '../../Components/Table';
import { dataBrMask } from '../../Utils/mask';
import Funcoes from '../../Utils/functions';
import ApiService from '../../Utils/services';

// import iconCopiar from '../../resources/icon-copiar.svg';

export default class ManualReport extends Component {

    constructor() {
        super();

        this.state = {
            redirect: false,
            path: '',
            loading: false,
            alert: false,
            alertText: '',
            empresas: [],
            empresa: '',
            dtInicio: '',
            dtFim: '',
            statusOptions: [
                { value: '', text: 'Selecione' },
                { value: 0, text: 'Pendente' },
                { value: 1, text: 'Pago' },
                { value: 2, text: 'Erro no Pagamento' },
            ],
            status: '',
            totalBoletos: 0,
            totalLiquidar: 0,
            page: 1,
            totalPages: 1,
            table: {
                campos: [
                    { titulo: 'Cód. Ref.', dado: 'codRef' },
                    { titulo: 'Empresa', dado: 'empresa' },
                    { titulo: 'Cliente', dado: 'cliente' },
                    { titulo: 'Status', dado: 'status' },
                    { titulo: 'Valor', dado: 'valor', thStyle: { textAlign: 'right' }, tdStyle: { textAlign: 'right' } }
                ],
                dados: []
            },
            mostraDados: false,
            mostraVazio: false
        };
    }

    componentDidMount() {
        this.listaEmpresas();
    }

    async listaEmpresas() {
        const userData = JSON.parse(sessionStorage.getItem('userData'));
        await ApiService.getBackoffice('empresa', userData.token)
            .then(result => {
                const res = result.data.data;
                var objects = [];
                objects[0] = { value: '', text: 'Selecione' };
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    objects[i + 1] = { value: item.emp_id, text: item.emp_nomefantasia };
                }
                this.setState({ empresas: objects });
            })
            .catch(err => this.setState({ empresas: [] }))
    }

    async listaBoletos() {
        const { empresa, dtInicio, dtFim, status, page, table } = this.state;
        if (status) {
            this.setState({ loading: true });
            const userData = JSON.parse(sessionStorage.getItem('userData'));
            var url;

            if (status === 0 || status === "0") {
                url = "liquidacaomanual?per_page=20&page=" + page;
            } else {
                url = "liquidacoes?per_page=20&page=" + page + "&liqb_status=" + status;
            }

            if (empresa) url = url + "&emp_id=" + empresa;
            if (dtInicio) url = url + "&dtpagto_inicial=" + Funcoes.data2Americano(dtInicio, '/');
            if (dtFim) url = url + "&dtpagto_final=" + Funcoes.data2Americano(dtFim, '/');

            await ApiService.getBackoffice(url, userData.token)
                .then(result => {
                    var res, objects = [], sts = ['Pendente', 'Pago', 'Erro no Pagamento'];
                    res = result.data.data;

                    for (var i = 0; i < res.data.length; i++) {
                        const item = res.data[i];
                        if (status === 0 || status === "0") {
                            objects.push({
                                codRef: item.ecob_codreferencia, empresa: item.empresa ? item.empresa[0].emp_nomefantasia : '',
                                cliente: item.clientecpf ? item.clientecpf.cli_nome : '', status: sts[status], valor: 'R$ ' + Funcoes.float2Preco(item.ecob_valor)
                            });
                        } else {
                            objects.push({
                                codRef: item.ecob_codreferencia, empresa: item.cobranca ? item.cobranca.empresa[0].emp_nomefantasia : '',
                                cliente: item.cobranca ? item.cobranca.clientecpf.cli_nome : '', status: sts[status], valor: 'R$ ' + Funcoes.float2Preco(item.ecob_valor)
                            });
                        }
                    }

                    this.setState({
                        totalBoletos: res.total, totalLiquidar: result.data.valor_total ? result.data.valor_total : 0, table: { campos: [...table.campos], dados: objects },
                        mostraDados: res.data.length > 0 ? true : false, mostraVazio: res.data.length === 0 ? true : false, loading: false
                    });
                })
                .catch(err => {
                    const { table } = this.state;
                    const valida = ApiService.handleErros(err.response);
                    if (valida.status === "unauthorized") {
                        sessionStorage.removeItem('userData');
                        this.setState({ path: '/' });
                    }
                    if (valida.status === "serverError") { this.setState({ path: '' }); }
                    if (valida.status === "badRequest") { this.setState({ path: '' }); }
                    this.setState({ loading: false, alertText: valida.response, alert: true, table: { campos: [...table.campos], dados: [] } });
                })

        } else {
            this.setState({ alertText: 'Selecione um status para continuar!', alert: true });
        }
    }

    exportarListagem() {
        const { empresa, dtInicio, dtFim, status } = this.state;
        if (status) {
            this.setState({ loading: true });
            const userData = JSON.parse(sessionStorage.getItem('userData'));
            var url;

            if (status === 0 || status === "0") {
                url = "liquidacaomanual/exportar?per_page=-1";
            } else {
                url = "liquidacoes/exportar?liqb_status=" + status;
            }

            if (empresa) url = url + "&emp_id=" + empresa;
            if (dtInicio) url = url + "&dtpagto_inicial=" + Funcoes.data2Americano(dtInicio, '/');
            if (dtFim) url = url + "&dtpagto_final=" + Funcoes.data2Americano(dtFim, '/');

            const xhr = new XMLHttpRequest();
            xhr.open("GET", ApiService.TchauBoletoApiUrl() + "/backoffice/" + url);
            xhr.setRequestHeader("Content-Type", "application/vnd.ms-excel");
            xhr.setRequestHeader("Authorization", "Bearer " + userData.token);
            xhr.responseType = "blob";

            xhr.onload = () => {
                var blob = new Blob([xhr.response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'liquidacoes.xlsx';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                this.setState({ loading: false });
            };
            xhr.send();
        } else {
            this.setState({ alertText: 'Selecione um status para continuar!', alert: true });
        }
    }

    render() {
        const { redirect, path, loading, alert, alertText, empresas, empresa, dtInicio, dtFim, statusOptions, status, table, totalBoletos, totalLiquidar, page,
            totalPages, mostraDados, mostraVazio } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} path={path} />
                <Loading show={loading} />
                <Alert show={alert} texto={alertText} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <SideMenu menuAtivo={10} />

                <div className="body">
                    <Header />

                    <div className="liquida-boleto">
                        <div className="tituloButton">
                            <div>
                                <h1>Liquidação Automática</h1>
                                <p className="descricao">Abaixo listamos todas cobranças de liquidação de boleto conforme filtro aplicado.</p>
                            </div>
                            <button className="btn bd-roxo cl-roxo" onClick={() => this.exportarListagem(1)}>Exportar Listagem</button>
                        </div>

                        <div className="filtros">
                            <div className="panel">
                                <Input type="select" label="Empresa" optionsData={empresas} grow={0} style={{ width: 245 }}
                                    value={empresa} onChange={e => this.setState({ empresa: e.target.value })} />
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <Input type="tel" label="Data do Pagamento" grow={0} placeholder="00/00/0000" value={dtInicio} maxLength={10}
                                        style={{ width: 150, height: 40, marginRight: 20 }}
                                        onChange={e => this.setState({ dtInicio: dataBrMask(e.target.value) })} />
                                    <p style={{ marginTop: 20, marginRight: 20, marginBottom: 0, fontSize: 22 }}>à</p>
                                    <Input type="tel" label="a" grow={0} placeholder="00/00/0000" value={dtFim} maxLength={10}
                                        style={{ width: 150, height: 40, marginRight: 20 }}
                                        onChange={e => this.setState({ dtFim: dataBrMask(e.target.value) })} />
                                </div>
                                <Input type="select" label="Status" optionsData={statusOptions} grow={0} style={{ width: 170 }}
                                    value={status} onChange={e => this.setState({ status: e.target.value })} />
                                <button className="btn bg-roxo cl-branco" onClick={() => this.listaBoletos()}>Filtrar</button>
                            </div>
                        </div>

                        <div className="conteudo">
                            {mostraDados ? (
                                <div className="panel liqAutomatico">
                                    <div className="panelBody">
                                        <Table campos={table.campos}
                                            dados={table.dados} />
                                    </div>
                                </div>
                            ) : null}
                            {mostraVazio ? (
                                <div className="panel">
                                    <div className="panelBody">
                                        <p>Nenhum resultado encontrado!</p>
                                    </div>
                                </div>
                            ) : null}
                            <div className="inLine">
                                <p className="label" style={{ marginRight: 52 }}>Total de boletos: <span className="value">{totalBoletos}</span></p>
                                <p className="label">Total a liquidar: <span className="value">R$ {Funcoes.float2Preco(totalLiquidar)}</span></p>
                            </div>
                        </div>
                        {totalPages > 1 && page < totalPages ? (
                            <div className="carrega-mais" style={{ marginTop: 20 }}>
                                <button onClick={() => {
                                    if (page + 1 <= totalPages) {
                                        this.setState({ page: page + 1 });
                                        this.listaBoletos(page + 1);
                                    }
                                }}>+</button>
                            </div>
                        ) : null}
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}