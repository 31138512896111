import React, { Component, Fragment } from 'react';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Input from '../../Components/Input';
import Table from '../../Components/Table';
import Paginacao from '../../Components/Paginacao';
import ApiService from '../../Utils/services';
import Funcoes from '../../Utils/functions';
import { dataBrMask } from '../../Utils/mask';

export default class Payment extends Component {

    constructor() {
        super();

        this.handlePage = this.handlePage.bind(this);
        this.state = {
            redirect: false,
            path: '',
            loading: false,
            alert: false,
            alertText: '',
            empresas: [
                { value: '', text: 'Todas as Empresas' }
            ],
            linhaOptions: [
                { value: '', text: 'Selecione' },
                { value: 0, text: 'Não Inserida' },
                { value: 1, text: 'Inserida' }
            ],
            empresa: '',
            linhaDigitavel: '',
            dtInicio: '',
            dtFim: '',
            table: {
                campos: [
                    { titulo: 'Cód. Referência', dado: 'codRef' },
                    { titulo: 'Empresa', dado: 'emp' },
                    { titulo: 'Cliente', dado: 'cli' },
                    { titulo: 'Data Pagamento', dado: 'dtPagto', thStyle: { textAlign: 'center' }, tdStyle: { textAlign: 'center' } },
                    { titulo: 'Data Liberação', dado: 'dtLibera', thStyle: { textAlign: 'center' }, tdStyle: { textAlign: 'center' } },
                    { titulo: 'Cartões', dado: 'cartoes', thStyle: { textAlign: 'center' }, tdStyle: { textAlign: 'center' } },
                    { titulo: 'Plano de Pagto', dado: 'parcelas', thStyle: { textAlign: 'center' }, tdStyle: { textAlign: 'center' } },
                    { titulo: 'Valor Pago', dado: 'valLiq', thStyle: { textAlign: 'right' }, tdStyle: { textAlign: 'right' } },
                ],
                dados: []
            },
            pagamentosDefault: [],
            totalRecebido: 0,
            exibeDados: false,
            mostraVazio: false
        };
    }

    componentDidMount() {
        this.listaEmpresas();
        this.listaPagamentos();
    }

    async listaEmpresas() {
        this.setState({ loading: true });
        const userData = JSON.parse(sessionStorage.getItem('userData'));
        await ApiService.getBackoffice('empresa', userData.token)
            .then(result => {
                const res = result.data.data;
                var objects = [];
                objects[0] = { value: '', text: 'Todas as Empresas' };
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    objects[i + 1] = { value: item.emp_id, text: item.emp_nomefantasia };
                }
                this.setState({ empresas: objects, loading: false });
            })
            .catch(err => this.setState({ empresas: [], loading: false }))
    }

    async listaPagamentos(usaFiltro, page) {
        const { table, empresa, dtInicio, dtFim, linhaDigitavel } = this.state;
        this.setState({ loading: true });

        var url, pagina, pagAtual;
        const userData = JSON.parse(sessionStorage.getItem('userData'));
        pagina = Funcoes.getAllUrlParams(window.location.href);
        pagina = pagina.page ? pagina.page : 1;
        pagAtual = page ? page : pagina;

        if (pagAtual) {
            url = "pagamento?per_page=20&page=" + pagAtual;
        } else {
            url = "pagamento?per_page=20";
        }
        if (usaFiltro && empresa)
            url = url + "&emp_id=" + empresa;

        if (usaFiltro && dtInicio)
            url = url + "&dtpagto_inicial=" + Funcoes.data2Americano(dtInicio, '/');

        if (usaFiltro && dtFim)
            url = url + "&dtpagto_final=" + Funcoes.data2Americano(dtFim, '/');

        if (usaFiltro && linhaDigitavel)
            url = url + "&ecob_linhadigitavel=" + linhaDigitavel;

        await ApiService.getBackoffice(url, userData.token)
            .then(result => {
                const res = result.data.data.data;
                var objects = [];
                var totalRecebido = result.data.valor_total ? parseFloat(result.data.valor_total) : 0;
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    objects[i] = {
                        codRef: item.ecob_codreferencia, emp: item.empresa[0].emp_nomefantasia, cli: item.clientecpf ? item.clientecpf.cli_nome : '-',
                        dtPagto: item.ecob_dthrpagto ? Funcoes.data2Brazilian(item.ecob_dthrpagto.split(' ')[0], '-') + " " + item.ecob_dthrpagto.split(' ')[1] : '-',
                        dtLibera: item.ecob_dtprevrecebimento ? Funcoes.data2Brazilian(item.ecob_dtprevrecebimento, '-') : '-', cartoes: item.cartoes,
                        parcelas: item.parcelas ? item.parcelas < 10 ? '0' + item.parcelas : item.parcelas : '-',
                        valLiq: 'R$ ' + Funcoes.float2Preco(item.ecob_valorpago ? item.ecob_valorpago : 0)
                    }
                }
                this.setState({
                    table: { campos: [...table.campos], dados: objects }, pagamentosDefault: result.data.data, totalRecebido,
                    exibeDados: res.length > 0 ? true : false, mostraVazio: res.length > 0 ? false : true, loading: false
                });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    handlePage(page) {
        if (page) {
            this.setState({ path: '/pagamentos?page=' + page, redirect: true });
        } else {
            this.setState({ path: '/pagamentos', redirect: true });
        }
        this.listaPagamentos(0, page);
    }

    exportarListagem(usaFiltro) {
        const { empresa, dtInicio, dtFim, linhaDigitavel } = this.state;
        this.setState({ loading: true });
        const userData = JSON.parse(sessionStorage.getItem('userData'));
        var url = ApiService.TchauBoletoApiUrl() + "/backoffice/pagamento?export=true";
        if (usaFiltro && empresa)
            url = url + "&emp_id=" + empresa;

        if (usaFiltro && dtInicio)
            url = url + "&dtpagto_inicial=" + Funcoes.data2Americano(dtInicio, '/');

        if (usaFiltro && dtFim)
            url = url + "&dtpagto_final=" + Funcoes.data2Americano(dtFim, '/');

        if (usaFiltro && linhaDigitavel)
            url = url + "&ecob_linhadigitavel=" + linhaDigitavel;

        const xhr = new XMLHttpRequest();
        xhr.open("GET", url);
        xhr.setRequestHeader("Content-Type", "application/vnd.ms-excel");
        xhr.setRequestHeader("Authorization", "Bearer " + userData.token);
        xhr.responseType = "blob";

        xhr.onload = () => {
            var blob = new Blob([xhr.response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'pagamentos.xlsx';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.setState({ loading: false });
        };
        xhr.send();
    }

    render() {
        const { redirect, path, loading, alert, alertText, empresas, linhaOptions, empresa, linhaDigitavel, dtInicio, dtFim, table, pagamentosDefault, totalRecebido, exibeDados,
            mostraVazio } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} path={path} />
                <Loading show={loading} />
                <Alert show={alert} texto={alertText} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <SideMenu menuAtivo={5} />

                <div className="body">
                    <Header />

                    <div className="pagamentos">
                        <div className="tituloButton">
                            <div>
                                <h1>Pagamentos</h1>
                                <p className="descricao">Abaixo listamos todos os pagamentos recebidos conforme filtro aplicado.</p>
                            </div>
                            <button className="btn bd-roxo cl-roxo" onClick={() => this.exportarListagem(1)}>Exportar Listagem</button>
                        </div>

                        <div className="filtros">
                            <div className="panel">
                                <Input type="select" label="Empresa" optionsData={empresas} grow={0} value={empresa}
                                    onChange={e => this.setState({ empresa: e.target.value })} style={{ width: 'auto', marginRight: 25, height: 47 }} />
                                <Input type="select" label="Linha Digitável" optionsData={linhaOptions} grow={0} value={linhaDigitavel}
                                    onChange={e => this.setState({ linhaDigitavel: e.target.value })} style={{ width: 'auto', marginRight: 25, height: 47 }} />
                                <Input type="tel" label="Período" grow={0} value={dtInicio} maxLength="10"
                                    onChange={e => this.setState({ dtInicio: dataBrMask(e.target.value) })} />
                                <p>à</p>
                                <Input type="tel" grow={0} value={dtFim} onChange={e => this.setState({ dtFim: dataBrMask(e.target.value) })}
                                    maxLength="10" style={{ marginTop: 18, marginRight: 31 }} />
                                <button className="btn bg-roxo cl-branco" onClick={() => this.listaPagamentos(1)}>Filtrar</button>
                            </div>
                        </div>

                        <div className="panel">
                            {exibeDados ? (<>
                                <Table campos={table.campos}
                                    dados={table.dados} />
                                <div className="info">
                                    <p className="totais">Total Recebido:</p>
                                    <p className="valores cl-roxo">R$ {Funcoes.float2Preco(totalRecebido)}</p>
                                </div>
                            </>) : null}
                            {mostraVazio ? (
                                <p style={{ color: '#ABABAB', fontSize: 20 }}>Nenhum pagamento encontrado.</p>
                            ) : null}
                        </div>

                        {exibeDados ? (
                            <div className="infoPag">
                                <p>Total de registros: {pagamentosDefault.total}</p>
                                <Paginacao dados={pagamentosDefault}
                                    handlePage={this.handlePage} />
                            </div>
                        ) : null}
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}