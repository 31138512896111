import React, { Component, Fragment } from 'react';

import iconAviso from '../../resources/icon-aviso.svg';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Table from '../../Components/Table';
import Modal from '../../Components/Modal';

import { Link } from 'react-router-dom';
import ApiService from '../../Utils/services';

export default class Company extends Component {

    constructor(props) {
        super(props);

        this.acaoEditar = this.acaoEditar.bind(this);
        this.acaoExcluir = this.acaoExcluir.bind(this);
        this.state = {
            redirect: false,
            path: '',
            propsToPath: '',
            loading: false,
            alert: false,
            alertText: '',
            modal: false,
            table: {
                campos: [
                    { titulo: 'Nome', dado: 'nome' },
                    { titulo: 'E-mail', dado: 'email' },
                    { titulo: 'Status', dado: 'tag' },
                    { titulo: 'Ações', dado: 'acoes' },
                ],
                dados: []
            },
            exibeDados: false,
            empID: props.location.state ? props.location.state.empresa.id : null,
            empNome: props.location.state ? props.location.state.empresa.nome : 'Empresa',
            idExcluir: '',
            nomeExcluir: ''
        };
    }

    componentDidMount() {
        this.listaUsuariosEmpresa();
    }

    async listaUsuariosEmpresa() {
        const { table } = this.state;
        this.setState({ loading: true });
        const userData = JSON.parse(sessionStorage.getItem('userData'));
        await ApiService.getBackoffice('empresausuario?per_page=-1&emp_id=' + this.props.location.state.empresa.id, userData.token)
            .then(result => {
                const res = result.data.data;
                var objects = [];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    objects[i] = {
                        id: item.eusu_id, nome: item.eusu_nome.toUpperCase(), email: item.eusu_email, tag: item.eusu_status === 1 ? 3 : 4,
                        acoes: 'cadastro', nomeExcluir: item.eusu_nome
                    };
                }
                this.setState({ table: { campos: [...table.campos], dados: objects }, exibeDados: res.length > 0 ? true : false, loading: false });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    acaoEditar(id) {
        this.setState({ path: '/empresas/usuarios/novo', propsToPath: { ...this.props.location.state, usuario: { id } }, redirect: true });
    }

    acaoExcluir(idExcluir, nomeExcluir) {
        this.setState({ nomeExcluir, idExcluir, modal: true });
    }

    async apagaUsuario() {
        const { idExcluir } = this.state;
        this.setState({ loading: true });
        const userData = JSON.parse(sessionStorage.getItem('userData'));
        await ApiService.customRequest('backoffice/empresausuario/' + idExcluir, 'DELETE', null, userData.token)
            .then(result => {
                this.listaUsuariosEmpresa();
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    render() {
        const { redirect, path, propsToPath, loading, alert, alertText, modal, table, exibeDados, empNome, nomeExcluir } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} props={propsToPath} path={path} />
                <Alert show={alert} texto={alertText} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <Loading show={loading} />
                <Modal show={modal}>
                    <div>
                        <p style={{ fontSize: 16, color: '#999', textTransform: 'uppercase', fontWeight: 'bold', marginBottom: 22 }}>Atenção</p>
                        <img src={iconAviso} alt="Icone de Aviso" />
                        <p style={{ marginTop: 23, marginBottom: 22, fontSize: 16 }}>
                            Você realmente deseja <b>apagar</b> o usuário <span style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>{nomeExcluir}</span>? <br /><br />
                            Essa ação <b>não</b> poderá ser desfeita!
                        </p>
                        <div style={{ display: 'flex' }}>
                            <button className="btn cl-branco" onClick={() => this.setState({ modal: false })}
                                style={{ background: '#4F4F4F', fontWeight: 500, textTransform: 'uppercase', flex: 1, marginRight: 15 }}>Cancelar</button>
                            <button className="btn cl-branco" onClick={() => { this.setState({ modal: false }); this.apagaUsuario(); }}
                                style={{ background: '#EB5757', fontWeight: 500, textTransform: 'uppercase', flex: 1 }}>Apagar</button>
                        </div>
                    </div>
                </Modal>
                <SideMenu menuAtivo={2} />

                <div className="body">
                    <Header />

                    <div className="empresas-usuarios">
                        <div className="tituloButton">
                            <div>
                                <h1 style={{ textTransform: 'capitalize' }}>Usuários - {empNome.toLowerCase()}</h1>
                                <p className="descricao">Abaixo a listagem de todos os usuários da empresa</p>
                            </div>
                            <button className="btn bd-roxo cl-roxo"
                                onClick={() => this.setState({ path: '/empresas/usuarios/novo', propsToPath: { ...this.props.location.state }, redirect: true })}>Novo Usuário</button>
                        </div>

                        <div className="panel">
                            {exibeDados ? (
                                <Table campos={table.campos}
                                    dados={table.dados}
                                    acaoEditar={this.acaoEditar}
                                    acaoExcluir={this.acaoExcluir} />
                            ) : null}
                        </div>
                        <div className="acoes">
                            <Link to="/empresas" className="btn">{'<'} Voltar</Link>
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}