import React, { Component, Fragment } from 'react';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Table from '../../Components/Table';
import Paginacao from '../../Components/Paginacao';
import ApiService from '../../Utils/services';
import Funcoes from '../../Utils/functions';

export default class Company extends Component {

    constructor() {
        super();

        this.acaoEditar = this.acaoEditar.bind(this);
        this.acaoUsuarios = this.acaoUsuarios.bind(this);
        this.handlePage = this.handlePage.bind(this);
        this.state = {
            redirect: false,
            path: '',
            propsToPath: null,
            loading: false,
            alert: false,
            alertText: '',
            table: {
                campos: [
                    { titulo: 'Empresa', dado: 'empresa' },
                    { titulo: 'Fantasia', dado: 'fantasia' },
                    { titulo: 'CNPJ', dado: 'cnpj' },
                    { titulo: 'Status', dado: 'tag' },
                    { titulo: 'Ações', dado: 'acoes' },
                ],
                dados: []
            },
            empresasDefault: [],
            exibeDados: false,
            mostraVazio: false
        };
    }

    componentDidMount() {
        this.carregaEmpresas();
    }

    async carregaEmpresas(page) {
        const { table } = this.state;

        this.setState({ loading: true });

        var url, pagina, pagAtual;
        const userData = JSON.parse(sessionStorage.getItem('userData'));
        pagina = Funcoes.getAllUrlParams(window.location.href);
        pagina = pagina.page ? pagina.page : 1;
        pagAtual = page ? page : pagina;

        if (pagAtual) {
            url = "empresa?page=" + pagAtual;
        } else {
            url = "empresa";
        }
        await ApiService.getBackoffice(url, userData.token)
            .then(result => {
                const res = result.data.data;
                var objects = [];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    objects[i] = {
                        id: item.emp_id, empresa: item.emp_razao.toUpperCase(), fantasia: item.emp_nomefantasia.toUpperCase(), cnpj: item.emp_cnpj,
                        tag: item.emp_status === 1 ? 3 : 4, acoes: 'default'
                    };
                }
                if (res.length > 0) {
                    this.setState({ exibeDados: true, loading: false, mostraVazio: false });
                } else {
                    this.setState({ loading: false, mostraVazio: true });
                }
                this.setState({ table: { campos: [...table.campos], dados: objects }, empresasDefault: result.data });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    acaoEditar(id) {
        this.setState({ path: '/empresas/nova', propsToPath: { empresa: { id } }, redirect: true });
    }

    acaoUsuarios(id, nome) {
        this.setState({ path: '/empresas/usuarios', propsToPath: { empresa: { id, nome } }, redirect: true });
    }

    handlePage(page) {
        if (page) {
            this.setState({ path: '/empresas?page=' + page, redirect: true });
        } else {
            this.setState({ path: '/empresas', redirect: true });
        }
        this.carregaEmpresas(page);
    }

    render() {
        const { loading, redirect, path, propsToPath, alert, alertText, table, empresasDefault, exibeDados, mostraVazio } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} props={propsToPath} path={path} />
                <Alert show={alert} texto={alertText} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <Loading show={loading} />
                <SideMenu menuAtivo={2} />

                <div className="body">
                    <Header />

                    <div className="empresas">
                        <div className="tituloButton">
                            <div>
                                <h1>Empresas</h1>
                                <p className="descricao">Abaixo a listagem de todas empresas cadastradas na plataforma</p>
                            </div>
                            <button className="btn bd-roxo cl-roxo" onClick={() => this.setState({ path: '/empresas/nova', redirect: true })}>Nova Empresa</button>
                        </div>
                        <div className="detalhes">

                            <div className="panel">
                                {exibeDados ? (<>
                                    <Table campos={table.campos}
                                        dados={table.dados}
                                        acaoEditar={this.acaoEditar}
                                        acaoUsuarios={this.acaoUsuarios} />
                                </>) : null}
                                {mostraVazio ? (
                                    <p style={{ color: '#ABABAB', fontSize: 20 }}>Nenhuma empresa encontrada!</p>
                                ) : null}
                            </div>
                            {exibeDados ? (<>
                                <div className="info">
                                    <p>Total de registros: {empresasDefault.total}</p>
                                    <Paginacao dados={empresasDefault}
                                        handlePage={this.handlePage} />
                                </div>
                            </>) : null}
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}