import React, { Component, Fragment } from 'react';

import '../../resources/css/generals.scss';
import '../../resources/css/pages.scss';
import '../../resources/css/responsive.scss';

import LogoTchauBoleto from '../../resources/logo-tchau-boleto.png';

import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import ApiService from '../../Utils/services';
import Funcoes from '../../Utils/functions';

export default class Login extends Component {

    constructor() {
        super();

        this.state = {
            email: '',
            senha: '',
            emailHelper: '',
            senhaHelper: '',
            redirect: false,
            loading: false,
            alert: false,
            alertText: ''
        };
    }

    validaCampos() {
        const { email, senha } = this.state;
        if (Funcoes.validaEmail(email) !== true) {
            this.setState({ emailHelper: 'O email digitado não é valido!' })
        } else {
            this.setState({ emailHelper: '' });
        }
        if (senha.length < 5) {
            this.setState({ senhaHelper: 'A quantidade minima de caracteres para a senha é de 5.' })
        } else {
            this.setState({ senhaHelper: '' })
        }

        if (Funcoes.validaEmail(email) === true && senha.length >= 5) {
            this.realizaLogin();
        }
    }

    async realizaLogin() {
        const { email, senha } = this.state;
        this.setState({ loading: true });

        const dataPost = {
            usu_email: email,
            usu_senha: senha
        }
        await ApiService.postBackoffice('login', dataPost)
            .then(result => {
                const res = result.data.data;
                const salvaSession = {
                    token: res.token,
                    usuario: {
                        id: res.usuario.usu_id,
                        nome: res.usuario.usu_nome,
                        email: res.usuario.usu_email,
                        status: res.usuario.usu_status
                    }
                }
                sessionStorage.setItem('userData', JSON.stringify(salvaSession));
                this.setState({ loading: false, redirect: true });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    handleSubmit(event) {
        if (event.key === "Enter") {
            this.validaCampos();
        }
    }

    render() {
        const { emailHelper, senhaHelper, redirect, loading, alert, alertText } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} path="/home" />
                <Loading show={loading} />
                <Alert show={alert} texto={alertText} action={() => this.setState({ alert: false })} />
                <div className="login" style={{ backgroundColor: '#FFF' }}>
                    <div className="contentLogin">
                        <div className="logo">
                            <img src={LogoTchauBoleto} alt="Logo Tchau Boleto" />
                        </div>
                        <div className="acessaForm">
                            <h2>Acessa sua conta</h2>
                            <div>
                                <label>Usuário</label>
                                <input type="tel" placeholder="Informe seu e-mail" onChange={e => this.setState({ email: e.target.value })} />
                                {emailHelper ? (
                                    <p style={{ marginBottom: 23, color: '#EA5353' }}>* {emailHelper}</p>
                                ) : emailHelper}
                            </div>
                            <div>
                                <label>Senha</label>
                                <input type="password" placeholder="Informe sua senha" onChange={e => this.setState({ senha: e.target.value })}
                                    onKeyPress={e => this.handleSubmit(e)} />
                                {senhaHelper ? (
                                    <p style={{ marginBottom: 23, color: '#EA5353' }}>* {senhaHelper}</p>
                                ) : null}
                            </div>
                            <button className="btn entrar bg-roxo" onClick={() => this.validaCampos()}>Entrar</button>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}