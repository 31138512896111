import React, { Component, Fragment } from 'react';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Input from '../../Components/Input';
// import { dataBrMask } from '../../Utils/mask';
// import Funcoes from '../../Utils/functions';
import ApiService from '../../Utils/services';

export default class RemittanceGenerate extends Component {

    constructor() {
        super();

        this.pegaArquivo = this.pegaArquivo.bind(this);
        this.state = {
            redirect: false,
            path: '',
            loading: false,
            alert: false,
            alertText: '',
            alertType: 0,
            arq: ''
        };
    }

    pegaArquivo(arquivo) {
        this.setState({ arq: arquivo[0] });
    }

    async processarRetorno() {
        const { arq } = this.state;
        if (arq) {
            this.setState({ loading: true });
            const userData = JSON.parse(sessionStorage.getItem('userData'));
            const formData = new FormData();
            formData.append('arquivo', arq, arq.name)

            await ApiService.postBackoffice('remessa/retorno', formData, userData.token)
                .then(res => {
                    this.setState({ alertText: 'Todas as transferências foram efetuadas com sucesso!', loading: false, alertType: 1, alert: true });
                })
                .catch(err => {
                    const valida = ApiService.handleErros(err.response);
                    if (valida.status === "unauthorized") {
                        sessionStorage.removeItem('userData');
                        this.setState({ path: '/' });
                    }
                    if (valida.status === "serverError") { this.setState({ path: '' }); }
                    if (valida.status === "badRequest") { this.setState({ path: '' }); }
                    this.setState({ loading: false, alertText: valida.response, alertType: 0, alert: true });
                })
        } else {
            this.setState({ alertText: 'Selecione um arquivo para continuar!', alertType: 0, alert: true });
        }
    }

    render() {
        const { redirect, path, loading, alert, alertText, alertType } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} path={path} />
                <Loading show={loading} />
                <Alert show={alert} tipo={alertType} texto={alertText}
                    action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <SideMenu menuAtivo={8} />

                <div className="body">
                    <Header />

                    <div className="debitos">
                        <h1>Processar Retorno</h1>
                        <p className="descricao">Processe o retorno da sua remessa gerada anteriormente.</p>

                        <div className="filtros">
                            <div className="panel">
                                <Input type="file" label="Selecionar arquivo" grow={0} style={{ width: 275 }}
                                    pegaArquivo={this.pegaArquivo} />
                                <button className="btn bg-roxo cl-branco" style={{ height: 50, marginTop: 37, marginLeft: 20 }}
                                    onClick={() => this.processarRetorno()}>Processar</button>
                            </div>
                        </div>

                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}