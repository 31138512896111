import React, { Component } from 'react';

import master from '../resources/bandeira-master.svg';
import iconEditar from '../resources/icon-editar.svg';
import iconUsuario from '../resources/icon-usuario.svg';
import iconLixeira from '../resources/icon-lixeira.svg';
import iconVisualizar from '../resources/icon-lupa.svg';
import iconBloqueado from '../resources/icon-bloqueado.svg';
import iconBloquear from '../resources/icon-bloquear.svg';

export default class Table extends Component {

    render() {
        const { campos, dados, selecionavel, acaoEditar, acaoUsuarios, acaoExcluir } = this.props;

        return (
            <table className="table">
                <thead>
                    <tr>
                        {selecionavel ? (
                            <th style={{ width: '5%' }}></th>
                        ) : null}
                        {campos.map((campo) => {
                            return (
                                <th key={campo.id} style={campo.thStyle}>{campo.titulo}</th>
                            )
                        })}
                    </tr>
                </thead>
                <tbody>
                    {
                        dados.map((item) => {
                            return (
                                <tr key={item.id}>
                                    {selecionavel ? (
                                        <td><input type="checkbox" className="checkBoxtable" /></td>
                                    ) : null}
                                    {campos.map((campo) => {
                                        if (typeof (item[campo.dado]) === "object" && typeof (item[campo.dado]) !== null) {
                                            return (
                                                <td style={item[campo.dado].tdStyle}>{item[campo.dado].val}</td>
                                            )
                                        } else {
                                            if (campo.dado === "bandeira") {
                                                return (
                                                    <td style={campo.tdStyle}><img src={master} alt="Icone de master" /></td>
                                                )
                                            } else if (campo.dado === "tag") {
                                                const arrTags = {
                                                    styles: [
                                                        { backgroundColor: '#F2994A', color: '#FFF', width: 70 },
                                                        { backgroundColor: '#EB5757', color: '#FFF', width: 70 },
                                                        { backgroundColor: '#27AE60', color: '#FFF', width: 70 },
                                                        { backgroundColor: '#27AE60', color: '#FFF', width: 70 },
                                                        { backgroundColor: '#BDBDBD', color: '#FFF', width: 70 }
                                                    ],
                                                    nomes: ['Pg Parcial', 'Em débito', 'Pago', 'Ativo', 'Inativo']
                                                }
                                                return (
                                                    <td className="tag" style={campo.tdStyle}>
                                                        <span style={arrTags.styles[item[campo.dado]]}>{arrTags.nomes[item[campo.dado]]}</span>
                                                    </td>
                                                )
                                            } else if (campo.dado === "tags") {
                                                const val = item[campo.dado];
                                                const splitVal = val.split(' ');
                                                const selectTag = splitVal.length > 1 ? splitVal[1] : splitVal[0];
                                                const arrTags = {
                                                    débito: { backgroundColor: '#EB5757', color: '#FFFFFF' },
                                                    parcial: { backgroundColor: '#F2994A', color: '#FFFFFF' },
                                                    pago: { backgroundColor: '#27AE60', color: '#FFFFFF' },
                                                    débitos: { backgroundColor: '#C5C5C5', color: '#FFFFFF' },
                                                    dia: { backgroundColor: '#1199da', color: '#FFFFFF', fontSize: 12 }
                                                }
                                                return (
                                                    <td className="tag" style={campo.tdStyle}>
                                                        <span style={arrTags[selectTag.toLowerCase()]}>{val}</span>
                                                    </td>
                                                )
                                            } else if (campo.dado === "acoes") {
                                                return (
                                                    <td className="acoes">
                                                        <img src={iconEditar} alt="Ícone de editar" style={{ marginRight: 18, cursor: 'pointer' }}
                                                            onClick={() => acaoEditar(item['id'])} title="Editar" />
                                                        {item[campo.dado] === "default" ? (
                                                            <img src={iconUsuario} alt="Ícone de usuário" style={{ cursor: 'pointer' }}
                                                                onClick={() => acaoUsuarios(item['id'], item['fantasia'])} title="Listar usuários" />
                                                        ) : null}
                                                        {item[campo.dado] === "cadastro" ? (
                                                            <img src={iconLixeira} alt="Ícone exclusão" style={{ cursor: 'pointer' }}
                                                                onClick={() => acaoExcluir(item['id'], item['nomeExcluir'])} title="Excluir" />
                                                        ) : null}
                                                    </td>
                                                )
                                            } else if (campo.dado === "acao") {
                                                const acao = item[campo.dado].split('|');
                                                return (
                                                    <td className="acoes">
                                                        {acao[0] === "visualizar" ? (
                                                            <button onClick={() => acaoEditar(item['id'])} title="Visualizar"
                                                                style={{ cursor: 'pointer', marginRight: 10 }}>
                                                                <img src={iconVisualizar} alt="Ícone de visualizar" style={{ width: 20 }} />
                                                            </button>
                                                        ) : null}
                                                        {acao[1] === "bloquear" ? (
                                                            <button title="Bloquear" onClick={() => acaoUsuarios(item)} style={{ cursor: 'pointer' }}>
                                                                <img src={iconBloquear} alt="Ícone de Bloquear" />
                                                            </button>
                                                        ) : null}
                                                        {acao[1] === "desbloquear" ? (
                                                            <button title="Desbloquear" onClick={() => acaoUsuarios(item)} style={{ cursor: 'pointer' }}>
                                                                <img src={iconBloqueado} alt="Ícone de Desbloquear" />
                                                            </button>
                                                        ) : null}
                                                    </td>
                                                )
                                            } else {
                                                return (
                                                    <td style={campo.tdStyle}>{item[campo.dado]}</td>
                                                )
                                            }
                                        }
                                    })}
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        );
    }

}