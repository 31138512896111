import React, { Component, Fragment } from 'react';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Funcoes from '../../Utils/functions';

export default class ChargesDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            redirect: false,
            path: '',
            propsToPath: null,
            loading: false,
            alert: false,
            alertText: '',
            cobranca: {},
            grupoCobranca: ['', 'Taxa Empresa', 'Taxa Cliente', 'Taxa Híbrida']
        };
    }

    componentDidMount() {
        if (this.props.location.state) {
            this.setState({ cobranca: this.props.location.state.cobranca });
        } else {
            this.setState({ path: '/consultar-cobranca', redirect: true });
        }
    }

    render() {
        const { redirect, path, propsToPath, loading, alert, alertText, cobranca, grupoCobranca } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} path={path} props={propsToPath} />
                <Loading show={loading} />
                <Alert show={alert} texto={alertText} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <SideMenu menuAtivo={9} />

                <div className="body">
                    <Header />

                    <div className="cobrancas detalhes">
                        <div className="tituloButton">
                            <div>
                                <h1>Detalhes da Cobrança</h1>
                                <p className="descricao">Abaixo listamos todas cobranças conforme filtro aplicado.</p>
                            </div>
                            <button className="btn bd-roxo cl-roxo" onClick={() => this.setState({
                                path: '/consultar-cobranca/historico',
                                propsToPath: {
                                    cobranca: this.props.location.state.cobranca, paginacao: this.props.location.state.paginacao, historico: cobranca.historico,
                                    id: cobranca.id, codRef: cobranca.ecob_codreferencia
                                }, redirect: true
                            })}>Histórico da Cobrança</button>
                        </div>

                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Dados do Cliente</h3>
                            </div>
                            <div className="panelBody">
                                <div>
                                    <p>Nome do Cliente:</p>
                                    <p>{cobranca.ecob_nomerazao}</p>
                                </div>
                                <div>
                                    <p>CPF/CNPJ:</p>
                                    <p>{cobranca.ecob_cpfcnpj}</p>
                                </div>
                                {cobranca.clientecpf ? (
                                    <div>
                                        <p>Celular:</p>
                                        <p>{cobranca.clientecpf.cli_celular}</p>
                                    </div>
                                ) : null}
                            </div>
                        </div>

                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Dados da Cobrança</h3>
                            </div>
                            <div className="panelBody div6">
                                <div>
                                    <p>Cód. Referência:</p>
                                    <p>{cobranca.ecob_codreferencia}</p>
                                </div>
                                <div>
                                    <p>Data de Inclusão:</p>
                                    <p>{cobranca.ecob_dthrinclusao ? Funcoes.data2Brazilian(cobranca.ecob_dthrinclusao.split(' ')[0]) + " " + Funcoes.removeSegundos(cobranca.ecob_dthrinclusao.split(' ')[1]) : ''} </p>
                                </div>
                                <div>
                                    <p>Data de Vencimento:</p>
                                    <p>{cobranca.ecob_dtvencto ? Funcoes.data2Brazilian(cobranca.ecob_dtvencto) : ''}</p>
                                </div>
                                <div>
                                    <p>Limite Pagto:</p>
                                    <p>{cobranca.ecob_limiterecebimento ? cobranca.ecob_limiterecebimento + ' dias' : 'Sem data limite'}</p>
                                </div>
                                <div>
                                    <p>Valor:</p>
                                    <p>R$ {Funcoes.float2Preco(cobranca.ecob_valor)}</p>
                                </div>
                                <div>
                                    <p>Grupo de Cobrança:</p>
                                    <p>{grupoCobranca[cobranca.ecob_tipocobranca]}</p>
                                </div>
                                <div>
                                    <p>Linha Digitável:</p>
                                    <p>{cobranca.ecob_linhadigitavel}</p>
                                </div>
                            </div>
                        </div>

                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Dados do Pagamento</h3>
                            </div>
                            <div className="panelBody">
                                <div>
                                    <p>Status:</p>
                                    <p className={cobranca.ecob_status === 0 ? "pendente" : "pago"}>{cobranca.ecob_status === 0 ? "Pendente" : "Pago"}</p>
                                </div>
                                {cobranca.ecob_status === 1 ? (<>
                                    <div>
                                        <p>Data/Hora do Pagamento:</p>
                                        <p>{cobranca.ecob_dthrpagto ? Funcoes.data2Brazilian(cobranca.ecob_dthrpagto.split(' ')[0]) + " " + Funcoes.removeSegundos(cobranca.ecob_dthrpagto.split(' ')[1]) : ''} </p>
                                    </div>
                                    <div>
                                        <p>Valor Pago:</p>
                                        <p>R$ {Funcoes.float2Preco(cobranca.ecob_valorpago)}</p>
                                    </div>
                                </>) : null}
                            </div>
                        </div>

                        <button className="btn btnVoltar" onClick={() => {
                            if (this.props.location.state) {
                                const { pagina, valorFiltro, filtro } = this.props.location.state.paginacao;
                                this.setState({ propsToPath: { pagina: pagina, valorFiltro: valorFiltro, filtro: filtro }, path: '/consultar-cobranca', redirect: true })
                            } else {
                                this.setState({ path: '/consultar-cobranca', redirect: true })
                            }
                        }}>{'<'} Voltar</button>

                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}