import React, { Component, Fragment } from 'react';
import $ from "jquery";
import { CopyToClipboard } from 'react-copy-to-clipboard';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Input from '../../Components/Input';
import Table from '../../Components/Table';
import { dataBrMask } from '../../Utils/mask';
import Funcoes from '../../Utils/functions';
import ApiService from '../../Utils/services';

import iconCopiar from '../../resources/icon-copiar.svg';

export default class ManualSettlement extends Component {

    constructor() {
        super();

        this.state = {
            redirect: false,
            path: '',
            loading: false,
            alert: false,
            alertText: '',
            empresas: [],
            empresa: '',
            dtInicio: '',
            dtFim: '',
            totalBoletos: 0,
            totalLiquidar: 0,
            page: 1,
            totalPages: 1,
            table: {
                campos: [
                    { titulo: 'Empresa', dado: 'empresa' },
                    { titulo: 'Cliente', dado: 'cliente' },
                    { titulo: 'CPF/CNPJ', dado: 'cpfCnpj' },
                    { titulo: 'Data/Hora Pagto', dado: 'dataHrPagto' },
                    { titulo: 'Tipo Cobrança', dado: 'tpCobranca' },
                    { titulo: 'Valor Pago', dado: 'valorPago' },
                    { titulo: 'Cód. Ref.', dado: 'codRef' }
                ],
                dados: []
            },
            copiado: false
        };
    }

    componentDidMount() {
        this.listaEmpresas();
        this.listaBoletos();
    }

    async listaEmpresas() {
        const userData = JSON.parse(sessionStorage.getItem('userData'));
        await ApiService.getBackoffice('empresa', userData.token)
            .then(result => {
                const res = result.data.data;
                var objects = [];
                objects[0] = { value: '', text: 'Selecione' };
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    objects[i + 1] = { value: item.emp_id, text: item.emp_nomefantasia };
                }
                this.setState({ empresas: objects });
            })
            .catch(err => this.setState({ empresas: [] }))
    }

    async listaBoletos(pagAtual, idBoletoIDX) {
        const { table, empresa, dtInicio, dtFim, page } = this.state;
        this.setState({ loading: true });
        const userData = JSON.parse(sessionStorage.getItem('userData'));
        const pageAtual = pagAtual ? pagAtual : page;
        var url = "liquidacaomanual?per_page=20&page=" + pageAtual;
        if (empresa) url = url + "&emp_id=" + empresa;
        if (dtInicio) url = url + "&dtpagto_inicial=" + Funcoes.data2Americano(dtInicio, '/');
        if (dtFim) url = url + "&dtpagto_final=" + Funcoes.data2Americano(dtFim, '/');

        await ApiService.getBackoffice(url, userData.token)
            .then(result => {
                const res = result.data.data;
                var objects = [];
                for (var i = 0; i < res.data.length; i++) {
                    const item = res.data[i];
                    const explodeData = item.ecob_dthrpagto.split(' ');
                    //1-empresa, 2-cliente, 3-hibrido
                    objects.push({
                        id: item.ecob_id, empresa: item.empresa[0].emp_nomefantasia, cliente: item.ecob_nomerazao, cpfCnpj: item.ecob_cpfcnpj,
                        dataHrPagto: Funcoes.data2Brazilian(explodeData[0], '-') + " " + explodeData[1].split(':')[0] + ":" + explodeData[1].split(':')[1],
                        tpCobranca: item.ecob_tipocobranca === 1 ? 'Taxa Empresa' : item.ecob_tipocobranca === 2 ? 'Taxa Cliente' : 'Taxa Hibrida',
                        valorPago: 'R$ ' + Funcoes.float2Preco(item.ecob_valorpago), linhaDigitavel: item.ecob_linhadigitavel ? item.ecob_linhadigitavel : '-', valor: item.ecob_valor,
                        codRef: item.ecob_codreferencia
                    });
                }
                if (idBoletoIDX || idBoletoIDX === 0) {
                    objects.splice(idBoletoIDX, 1);
                }
                this.setState({
                    table: { campos: [...table.campos], dados: objects }, exibeDados: res.data.length > 0 ? true : false, totalLiquidar: result.data.valor_total,
                    mostraVazio: res.data.length > 0 ? false : true, totalBoletos: res.total, totalPages: res.last_page, loading: false
                });
            })
            .catch(err => {
                const { table } = this.state;
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") { this.setState({ path: '' }); }
                if (valida.status === "badRequest") { this.setState({ path: '' }); }
                this.setState({ loading: false, alertText: valida.response, alert: true, table: { campos: [...table.campos], dados: [] } });
            })
    }

    async liquidaBoleto(idBoleto, index) {
        const { page } = this.state;
        const liqb_autenticacao = $(".autenticacao" + idBoleto).val();
        const dtHr = $(".dataHora" + idBoleto).val();
        const liqb_datahora = Funcoes.data2Americano(dtHr.split(" ")[0], '/') + " " + dtHr.split(" ")[1] + ":00";
        if (liqb_autenticacao && dtHr) {
            this.setState({ loading: true });
            const userData = JSON.parse(sessionStorage.getItem('userData'));
            const formData = {
                "ecob_id": idBoleto,
                "liqb_autenticacao": liqb_autenticacao,
                "liqb_datahora": liqb_datahora
            };
            await ApiService.postBackoffice('liquidacaomanual', formData, userData.token)
                .then(result => {
                    this.listaBoletos(page, index);
                })
                .catch(err => {
                    const valida = ApiService.handleErros(err.response);
                    if (valida.status === "unauthorized") {
                        sessionStorage.removeItem('userData');
                        this.setState({ path: '/' });
                    }
                    if (valida.status === "serverError") { this.setState({ path: '' }); }
                    if (valida.status === "badRequest") { this.setState({ path: '' }); }
                    this.setState({ loading: false, alertText: valida.response, alert: true });
                })
        }
    }

    render() {
        const { redirect, path, loading, alert, alertText, empresas, empresa, dtInicio, dtFim, table, totalBoletos, totalLiquidar, copiado, page, totalPages } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} path={path} />
                <Loading show={loading} />
                <Alert show={alert} texto={alertText} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <SideMenu menuAtivo={6} />

                <div className="body">
                    <Header />

                    <div className="liquida-boleto">
                        <h1>Liquidação Manual de Boleto</h1>
                        <p className="descricao">Abaixo listamos todas cobranças pendentes de liquidação de boleto conforme filtro aplicado.</p>

                        <div className="filtros">
                            <div className="panel">
                                <Input type="select" label="Empresa" optionsData={empresas} grow={0} style={{ width: 245 }}
                                    value={empresa} onChange={e => this.setState({ empresa: e.target.value })} />
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <Input type="tel" label="Data do Pagamento" grow={0} placeholder="00/00/0000" value={dtInicio} maxLength={10}
                                        style={{ width: 245, height: 40, marginRight: 20 }}
                                        onChange={e => this.setState({ dtInicio: dataBrMask(e.target.value) })} />
                                    <p style={{ marginTop: 20, marginRight: 20, marginBottom: 0, fontSize: 22 }}>à</p>
                                    <Input type="tel" label="a" grow={0} placeholder="00/00/0000" value={dtFim} maxLength={10}
                                        style={{ width: 245, height: 40, marginRight: 20 }}
                                        onChange={e => this.setState({ dtFim: dataBrMask(e.target.value) })} />
                                </div>
                                <button className="btn bg-roxo cl-branco" onClick={() => this.listaBoletos()}>Filtrar</button>
                            </div>
                        </div>

                        <div className="conteudo">
                            <div className="inLine">
                                <p className="label" style={{ marginRight: 52 }}>Total de boletos: <span className="value">{totalBoletos}</span></p>
                                <p className="label">Total a liquidar: <span className="value">R$ {Funcoes.float2Preco(totalLiquidar)}</span></p>
                            </div>

                            {table.dados.map((item, idx) => {
                                return (
                                    <div className="panel" key={idx}>
                                        <div className="panelBody">
                                            <Table campos={table.campos}
                                                dados={[table.dados[idx]]} />
                                            <div className="inLine" style={{ marginTop: 30 }}>
                                                <p className="title">Linha Digitável: <span className="valor">{item.linhaDigitavel}</span></p>
                                                <CopyToClipboard text={item.linhaDigitavel} onCopy={() => {
                                                    this.setState({ copiado: true });
                                                    setTimeout(() => {
                                                        this.setState({ copiado: false });
                                                    }, 3000);
                                                }}>
                                                    <button style={{ marginRight: 63, marginLeft: 11 }}>
                                                        <img src={iconCopiar} alt="Copia linha digitável" />
                                                    </button>
                                                </CopyToClipboard>
                                                <p className="title">Valor: <span className="valor">R$ {Funcoes.float2Preco(item.valor)}</span></p>
                                            </div>
                                            {copiado ? <span style={{ fontSize: 12, color: '#a93a3a' }}>Linha digitável copiada para área de transferência!</span> : null}
                                            <div className="inLine" style={{ marginTop: 27 }}>
                                                <Input type="text" label="Autenticação" grow={0} style={{ minWidth: 275 }} className={"autenticacao" + item.id} />
                                                <Input type="mask" label="Data/Hora Autenticação" grow={0} className={"dataHora" + item.id} mascara="99/99/9999 99:99" />
                                                <button className="btn" onClick={() => this.liquidaBoleto(item.id, idx)}>Liquidado</button>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        {totalPages > 1 && page < totalPages ? (
                            <div className="carrega-mais" style={{ marginTop: 20 }}>
                                <button onClick={() => {
                                    if (page + 1 <= totalPages) {
                                        this.setState({ page: page + 1 });
                                        this.listaBoletos(page + 1);
                                    }
                                }}>+</button>
                            </div>
                        ) : null}
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}