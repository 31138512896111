import React, { Component, Fragment } from 'react';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Table from '../../Components/Table';
import Input from '../../Components/Input';
import Funcoes from '../../Utils/functions';
import ApiService from '../../Utils/services';

export default class Debit extends Component {

    constructor() {
        super();

        this.state = {
            redirect: false,
            path: '',
            loading: false,
            alert: false,
            alertText: '',
            empresas: [
                { value: '', text: 'Selecione' }
            ],
            empresa: '',
            table: {
                campos: [
                    { titulo: 'Mês/Ano', dado: 'mesAno', thStyle: { textAlign: 'center', width: 150 }, tdStyle: { textAlign: 'center' } },
                    { titulo: 'Faturas', dado: 'faturas', thStyle: { width: 1 }, tdStyle: { textAlign: 'center' } },
                    { titulo: 'Total de Débitos', dado: 'totalDebitos', thStyle: { textAlign: 'right' }, tdStyle: { textAlign: 'right' } },
                    { titulo: 'Total Recebido', dado: 'totalRecebido', thStyle: { textAlign: 'right' }, tdStyle: { textAlign: 'right' } }
                ],
                dados: []
            },
            exibeDados: false,
            mostraVazio: false,
            totalDebitos: 0,
            totalRecebido: 0
        };
    }

    componentDidMount() {
        this.listaEmpresas();
        this.listaDebitos(0);
    }

    async listaEmpresas() {
        const userData = JSON.parse(sessionStorage.getItem('userData'));
        await ApiService.getBackoffice('empresa', userData.token)
            .then(result => {
                const res = result.data.data;
                var objects = [];
                objects[0] = { value: '', text: 'Selecione' };
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    objects[i + 1] = { value: item.emp_id, text: item.emp_nomefantasia };
                }
                this.setState({ empresas: objects });
            })
            .catch(err => this.setState({ empresas: [] }))
    }

    async listaDebitos(usaFiltro) {
        const { table, empresa } = this.state;
        this.setState({ loading: true });
        const userData = JSON.parse(sessionStorage.getItem('userData'));
        var url = "debito";

        if (usaFiltro && empresa)
            url = url + "?emp_id=" + empresa;

        await ApiService.getBackoffice(url, userData.token)
            .then(result => {
                const res = result.data;
                var objects = [];
                var totalDebitos = 0, totalRecebido = 0;
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    const mesSplit = item.mes_ano.split('/');
                    const mesCompleto = mesSplit[0] < 10 ? '0' + mesSplit[0] : mesSplit[0];
                    objects[i] = {
                        mesAno: mesCompleto + "/" + mesSplit[1], faturas: item.faturas,
                        totalDebitos: item.total_debitos ? 'R$ ' + Funcoes.float2Preco(item.total_debitos) : 'R$ ' + Funcoes.float2Preco(0),
                        totalRecebido: item.total_recebido ? 'R$ ' + Funcoes.float2Preco(item.total_recebido) : 'R$ ' + Funcoes.float2Preco(0)
                    }
                    totalDebitos += item.total_debitos;
                    totalRecebido += item.total_recebido;
                }
                objects.push({
                    mesAno: 'Totais:', faturas: '', totalDebitos: 'R$ ' + Funcoes.float2Preco(totalDebitos),
                    totalRecebido: 'R$ ' + Funcoes.float2Preco(totalRecebido)
                });
                this.setState({
                    table: { campos: [...table.campos], dados: objects }, exibeDados: res.length > 0 ? true : false,
                    mostraVazio: res.length > 0 ? false : true, totalDebitos, totalRecebido, loading: false
                });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    render() {
        const { redirect, path, loading, alert, alertText, empresas, empresa, table, exibeDados, mostraVazio } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} path={path} />
                <Loading show={loading} />
                <Alert show={alert} texto={alertText} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <SideMenu menuAtivo={4} />

                <div className="body">
                    <Header />

                    <div className="debitos">
                        <h1>Débitos</h1>
                        <p className="descricao">Abaixo listamos todos os débitos de clientes importado para a plataforma.</p>

                        <div className="filtros">
                            <div className="panel">
                                <Input type="select" label="Empresa" optionsData={empresas} grow={0} style={{ width: 245 }}
                                    value={empresa} onChange={e => this.setState({ empresa: e.target.value })} />
                                <button className="btn bg-roxo cl-branco" onClick={() => this.listaDebitos(1)}>Filtrar</button>
                            </div>
                        </div>

                        <div className="panel dbts">
                            {exibeDados ? (<>
                                <Table campos={table.campos}
                                    dados={table.dados} />
                            </>) : null}
                            {mostraVazio ? (
                                <p style={{ color: '#ABABAB', fontSize: 20 }}>Não existem pendências.</p>
                            ) : null}
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}