import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { PrivateRoute } from './Utils/PrivateRoute';
import './index.css';

import Login from './Pages/Login/Login';
// import ForgotPassword from './Pages/Login/ForgotPassword';
// import Register from './Pages/Login/Register';
import Dashboard from './Pages/Dashboard/Dashboard';
import Charges from './Pages/Charges/Charges';
import ChargesDetails from './Pages/Charges/ChargesDetails';
import ChargesHistoric from './Pages/Charges/ChargesHistoric';
import Company from './Pages/Companies/Company';
import NewCompany from './Pages/Companies/NewCompany';
import CompanyUsers from './Pages/Companies/CompanyUsers';
import NewCompanyUser from './Pages/Companies/NewCompanyUser';
import Client from './Pages/Clients/Client';
import Debit from './Pages/Debits/Debit';
import Payment from './Pages/Payments/Payment';
import ManualReport from './Pages/ManualSettlement/ManualReport';
import ManualSettlement from './Pages/ManualSettlement/ManualSettlement';
import RemittanceGenerate from './Pages/Remittance/RemittanceGenerate';
import RemittanceReturn from './Pages/Remittance/RemittanceReturn';
import NotFound from './Pages/NotFound/NotFound';

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/" exact={true} component={Login} />
      <PrivateRoute path="/home" component={Dashboard} />
      <PrivateRoute path="/empresas" exact={true} component={Company} />
      <PrivateRoute path="/empresas/nova" exact={true} component={NewCompany} />
      <PrivateRoute path="/empresas/usuarios" exact={true} component={CompanyUsers} />
      <PrivateRoute path="/empresas/usuarios/novo" exact={true} component={NewCompanyUser} />
      <PrivateRoute path="/clientes" component={Client} />
      <PrivateRoute path="/debitos" exact={true} component={Debit} />
      <PrivateRoute path="/pagamentos" component={Payment} />
      <PrivateRoute path="/liquidacao-automatica" component={ManualReport} />
      <PrivateRoute path="/liquidar-manual" component={ManualSettlement} />
      <PrivateRoute path="/remessa" exact={true} component={RemittanceGenerate} />
      <PrivateRoute path="/remessa/gerar" exact={true} component={RemittanceGenerate} />
      <PrivateRoute path="/remessa/retorno" exact={true} component={RemittanceReturn} />
      <PrivateRoute path="/consultar-cobranca" exact={true} component={Charges} />
      <PrivateRoute path="/consultar-cobranca/detalhes" exact={true} component={ChargesDetails} />
      <PrivateRoute path="/consultar-cobranca/historico" exact={true} component={ChargesHistoric} />
      <Route component={NotFound} />
    </Switch>
  </BrowserRouter>,
  document.getElementById('root'));