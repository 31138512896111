import React, { Component, Fragment } from 'react';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Input from '../../Components/Input';
import Alert from '../../Components/Alert';
import Loading from '../../Components/LoaderScreen';
import Modal from '../../Components/Modal';
import { cepMask, cnpjMask, telefoneMask, dataBrMask, onlyNumbersMask } from '../../Utils/mask';
import { validaForm } from '../../Utils/FormValidator';
import ApiService from '../../Utils/services';
import Funcoes from '../../Utils/functions';

import { Link } from 'react-router-dom';
import $ from "jquery";
import { priceFormat } from 'jquery-price-format';
// import { SketchPicker } from 'react-color';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import iconCopiar from '../../resources/icon-copiar.svg';

export default class NewCompany extends Component {

    constructor() {
        super();

        this.handleChange = this.handleChange.bind(this);
        this.pegaArquivo = this.pegaArquivo.bind(this);
        this.pegaArqConfig = this.pegaArqConfig.bind(this);
        this.pegaArqLogin = this.pegaArqLogin.bind(this);
        this.state = {
            loading: false,
            redirect: false,
            path: '',
            alert: false,
            alertText: '',
            alertType: 0,
            statusOptions: [
                { value: '', text: 'Selecione' },
                { value: 1, text: 'Ativo' },
                { value: 2, text: 'Inativo' },
                { value: 3, text: 'Bloqueado' }
            ],
            tipoCobrancaOptions: [
                { value: '', text: 'Selecione' },
                { value: 1, text: 'Empresa' },
                { value: 2, text: 'Cliente' }
            ],
            simNaoOptions: [
                { value: '', text: 'Selecione' },
                { value: 0, text: 'Não' },
                { value: 1, text: 'Sim' }
            ],
            rzSocial: '',
            nomeFantasia: '',
            cnpj: '',
            telefone: '',
            emailContato: '',
            emailFinanceiro: '',
            status: '',
            cep: '',
            logradouro: '',
            numero: '',
            complemento: '',
            bairro: '',
            cidade: '',
            cidId: '',
            uf: '',
            banco: '',
            agencia: '',
            conta: '',
            digito: '',
            token: '',
            idContaDigital: '',
            numContaDigital: '',
            tipoCobranca: '',
            dtInicioTarifa: '',
            liquidaBoletos: '',
            tokenIntegracao: '',
            habilitaMsg: '',
            dtInicioCobMsg: '',
            termosUso: '',
            contrato: '',
            webhook: '',
            cartao_terceiro: '',
            copiado: false,
            imagemPerfil: '',
            imagemLogin: '',
            corPrincipal: '#000000',
            mostraPicker: false,
            logoTipo: '',
            urlPortal: '',
            arrMenus: [],
            modal: false,
            dataModal: {}
        };
    }

    async componentDidMount() {
        this.setState({ loading: this.props.location.state ? false : true });
        const userData = JSON.parse(sessionStorage.getItem('userData'));
        await ApiService.getBackoffice('menu', userData.token)
            .then(result => {
                const res = result.data;
                var objects = [];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    if (!item.pmnu_pai) {
                        objects.push({ ...item, filhos: [], checado: item.pmnu_padrao ? true : false });
                    } else {
                        for (var x = 0; x < objects.length; x++) {
                            if (objects[x].pmnu_id === item.pmnu_pai) {
                                objects[x]['filhos'].push({ ...item });
                            }
                        }
                    }
                }
                if (this.props.location.state) this.pegaEmpresaEditavel(objects);
                this.setState({ arrMenus: objects, loading: this.props.location.state ? true : false });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") { this.setState({ path: '' }); }
                if (valida.status === "badRequest") { this.setState({ path: '' }); }

                this.setState({ alertType: 0, alertText: valida.response, loading: false, alert: true });
            })
        $('.txCred input').priceFormat({
            prefix: '',
            centsSeparator: ',',
            thousandsSeparator: '.'
        });
        $('#tafPagto, #tafLiq, #tafTed, #valMensagem').priceFormat({
            prefix: '',
            centsSeparator: ',',
            thousandsSeparator: '.'
        });
    }

    async pegaEmpresaEditavel(menusProps) {
        const { arrMenus } = this.state;
        const { empresa } = this.props.location.state;
        this.setState({ loading: true });
        const userData = JSON.parse(sessionStorage.getItem('userData'));

        await ApiService.getBackoffice('empresa/' + empresa.id, userData.token)
            .then(result => {
                const res = result.data.data;
                this.setState({
                    rzSocial: res.emp_razao, nomeFantasia: res.emp_nomefantasia, cnpj: res.emp_cnpj, telefone: res.emp_telefone,
                    emailContato: res.emp_emailcontato, emailFinanceiro: res.emp_emailfinanceiro, status: res.emp_status, cep: res.emp_endcep,
                    logradouro: res.emp_endlogradouro, numero: res.emp_endnumero, complemento: res.emp_endcomplemento ? res.emp_endcomplemento : '',
                    cidade: res.cidade ? res.cidade.cid_nome : '', cidId: res.cidade ? res.cidade.cid_id : '', bairro: res.emp_endbairro,
                    uf: res.cidade ? res.cidade.estado[0].uf_sigla : '', banco: res.emp_ctabanco, agencia: res.emp_ctaagencia, conta: res.emp_ctanumero, digito: res.emp_ctadigito,
                    dtInicioTarifa: res.emp_tarifadatainicio ? Funcoes.data2Brazilian(res.emp_tarifadatainicio, '-') : '', cartao_terceiro: res.emp_cartaoterceiro,
                    tipoCobranca: res.emp_tarifapagtocobranca, token: res.emp_token, idContaDigital: res.ctad_id, numContaDigital: res.ctad_numero,
                    corPrincipal: res.config ? res.config.ecfg_corprincipal : '', urlPortal: res.config ? res.config.ecfg_url : '', liquidaBoletos: res.emp_liquidaboleto,
                    tokenIntegracao: res.emp_token, habilitaMsg: res.emp_habilitamsg, dtInicioCobMsg: res.emp_dtiniciocobmsg ? Funcoes.data2Brazilian(res.emp_dtiniciocobmsg, '-') : '',
                    termosUso: res.emp_termosdeuso ? res.emp_termosdeuso : '', contrato: res.emp_contrato ? res.emp_contrato : '', webhook: res.emp_urlwebhook, dataModal: {
                        totalClientes: res.total_clientes, totalCobrancas: res.total_cobrancas, cobrancasRecebidas: res.cobrancas_pagas, cobrancasBaixadas: res.cobrancas_baixadas,
                        totalRecebido: res.total_recebido
                    }
                });
                $('#txCred1').val(Funcoes.float2Preco(res.emp_txcred1x));
                $('#txCred2').val(Funcoes.float2Preco(res.emp_txcred2x));
                $('#txCred3').val(Funcoes.float2Preco(res.emp_txcred3x));
                $('#txCred4').val(Funcoes.float2Preco(res.emp_txcred4x));
                $('#txCred5').val(Funcoes.float2Preco(res.emp_txcred5x));
                $('#txCred6').val(Funcoes.float2Preco(res.emp_txcred6x));
                $('#txCred7').val(Funcoes.float2Preco(res.emp_txcred7x));
                $('#txCred8').val(Funcoes.float2Preco(res.emp_txcred8x));
                $('#txCred9').val(Funcoes.float2Preco(res.emp_txcred9x));
                $('#txCred10').val(Funcoes.float2Preco(res.emp_txcred10x));
                $('#txCred11').val(Funcoes.float2Preco(res.emp_txcred11x));
                $('#txCred12').val(Funcoes.float2Preco(res.emp_txcred12x));
                $('#tafPagto').val(Funcoes.float2Preco(res.emp_tarifapagto));
                $('#tafLiq').val(Funcoes.float2Preco(res.emp_tarifaliquidaboleto));
                $('#tafTed').val(Funcoes.float2Preco(res.emp_valorted));
                $('#valMensagem').val(res.emp_valormsg ? Funcoes.float2Preco(res.emp_valormsg) : '');

                var menus = menusProps ? menusProps : arrMenus;
                for (var x = 0; x < menus.length; x++) {
                    for (var i = 0; i < res.menus.length; i++) {
                        const id = res.menus[i];
                        if (menus[x].filhos.length > 0) {
                            for (var y = 0; y < menus[x].filhos.length; y++) {
                                if (id === menus[x].filhos[y].pmnu_id) {
                                    menus[x].filhos[y].checado = true;
                                }
                            }
                        }
                        if (id === menus[x].pmnu_id) {
                            menus[x].checado = true;
                        }
                    }
                }
                this.setState({ menus: menus, loading: false });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    async handleChange(event, attr, type) {
        if (type === "cnpj") {
            this.setState({ [attr]: cnpjMask(event.target.value) });
        } else if (type === "cep") {
            this.setState({ [attr]: cepMask(event.target.value) });
            if (event.target.value.length === 9) {
                this.setState({ loading: true });
                await ApiService.customRequest('geral/consultacep', 'POST', { cep: cepMask(event.target.value) })
                    .then(result => {
                        const res = result.data.data;
                        this.setState({
                            logradouro: res.logradouro, complemento: res.complemento, bairro: res.bairro, cidade: res.cidade.cid_nome,
                            cidId: res.cidade.cid_id, uf: res.uf, loading: false
                        });
                        document.getElementById('numEnd').focus();
                    })
                    .catch(err => this.setState({ loading: false }))
            }
        } else if (type === "telefone") {
            this.setState({ [attr]: telefoneMask(event.target.value) });
        } else if (type === "onlyNumbers") {
            this.setState({ [attr]: onlyNumbersMask(event.target.value) });
        } else if (type === "data") {
            this.setState({ [attr]: dataBrMask(event.target.value) });
        } else if (type === "editor") {
            this.setState({ [attr]: event });
        } else {
            this.setState({ [attr]: event.target.value });
        }
    }

    validaCampos() {
        const { rzSocial, nomeFantasia, cnpj, telefone, emailContato, emailFinanceiro, status, cep, logradouro, numero, complemento, bairro,
            cidade, cidId, uf, banco, agencia, conta, digito, corPrincipal, urlPortal, imagemPerfil, imagemLogin, logoTipo, tipoCobranca, dtInicioTarifa,
            liquidaBoletos, habilitaMsg, dtInicioCobMsg, termosUso, contrato, tokenIntegracao, webhook, cartao_terceiro } = this.state;
        const menuForm = document.getElementsByName('menu[]');
        var menus = [];
        for (var i = 0; i < menuForm.length; i++) {
            const item = menuForm[i];
            if (item.checked) menus.push(item.value);
        }
        const txCred1 = $('#txCred1').val();
        const txCred2 = $('#txCred2').val();
        const txCred3 = $('#txCred3').val();
        const txCred4 = $('#txCred4').val();
        const txCred5 = $('#txCred5').val();
        const txCred6 = $('#txCred6').val();
        const txCred7 = $('#txCred7').val();
        const txCred8 = $('#txCred8').val();
        const txCred9 = $('#txCred9').val();
        const txCred10 = $('#txCred10').val();
        const txCred11 = $('#txCred11').val();
        const txCred12 = $('#txCred12').val();
        const tafPagto = $('#tafPagto').val();
        const tafLiq = $('#tafLiq').val();
        const tafTed = $('#tafTed').val();
        const valMsg = $('#valMensagem').val();

        //Se o usuário estiver incluindo uma nova empresa os campos Logo Portal Parceiro e Logotipo do White Label são obrigatórios
        var validaImagensConfig = true; //imagemPerfil, logoTipo
        if (this.props.location.state) {
            validaImagensConfig = false;
        }

        let form = [
            { campo: 'Razão Social', attr: 'rzSocial', valor: rzSocial },
            { campo: 'Nome Fantasia', attr: 'nomeFantasia', valor: nomeFantasia },
            { campo: 'CNPJ', attr: 'cnpj', valor: cnpj, minLength: 18 },
            { campo: 'Telefone', attr: 'telefone', valor: telefone, minLength: 14 },
            { campo: 'E-mail Contato', attr: 'emailContato', valor: emailContato, minLength: 8, tipo: "email" },
            { campo: 'E-mail Financeiro', attr: 'emailFinanceiro', valor: emailFinanceiro, minLength: 8, tipo: "email" },
            { campo: 'CEP', attr: 'cep', valor: cep, minLength: 9 },
            { campo: 'Rua, Av..', attr: 'logradouro', valor: logradouro, minLength: 5 },
            { campo: 'Número', attr: 'numero', valor: numero },
            { campo: 'Bairro', attr: 'bairro', valor: bairro, minLength: 4 },
            { campo: 'Cidade', attr: 'cidade', valor: cidade, minLength: 4 },
            { campo: 'Banco', attr: 'banco', valor: banco },
            { campo: 'Agência', attr: 'agencia', valor: agencia },
            { campo: 'Conta', attr: 'conta', valor: conta },
            { campo: 'Digíto', attr: 'digito', valor: digito },
            // { campo: 'Token', attr: 'token', valor: token },
            // { campo: 'ID Conta Digital (Exoss)', attr: 'idContaDigital', valor: idContaDigital },
            // { campo: 'Nº Conta Digital (Exoss)', attr: 'numContaDigital', valor: numContaDigital },
            { campo: 'Tx. Cred. 1', attr: 'txCred1', valor: txCred1 },
            { campo: 'Tx. Cred. 2', attr: 'txCred2', valor: txCred2 },
            { campo: 'Tx. Cred. 3', attr: 'txCred3', valor: txCred3 },
            { campo: 'Tx. Cred. 4', attr: 'txCred4', valor: txCred4 },
            { campo: 'Tx. Cred. 5', attr: 'txCred5', valor: txCred5 },
            { campo: 'Tx. Cred. 6', attr: 'txCred6', valor: txCred6 },
            { campo: 'Tx. Cred. 7', attr: 'txCred7', valor: txCred7 },
            { campo: 'Tx. Cred. 8', attr: 'txCred8', valor: txCred8 },
            { campo: 'Tx. Cred. 9', attr: 'txCred9', valor: txCred9 },
            { campo: 'Tx. Cred. 10', attr: 'txCred10', valor: txCred10 },
            { campo: 'Tx. Cred. 11', attr: 'txCred11', valor: txCred11 },
            { campo: 'Tx. Cred. 12', attr: 'txCred12', valor: txCred12 },
            { campo: 'Tarifa de Pagto', attr: 'tafPagto', valor: tafPagto },
            { campo: 'Data Início Tarifa', attr: 'dtInicioTarifa', valor: dtInicioTarifa },
            { campo: 'Tipo Cobrança Tarifa', attr: 'tipoCobranca', valor: tipoCobranca },
            { campo: 'Tarifa Líquida Boleto', attr: 'tafLiq', valor: tafLiq },
            { campo: 'Habilita Mensagem', attr: 'habilitaMsg', valor: habilitaMsg },
            { campo: 'Cor Principal', attr: 'corPrincipal', valor: corPrincipal },
            { campo: 'Url Portal', attr: 'urlPortal', valor: urlPortal }
        ];

        const valida = validaForm(form);

        if (valida.valido && status && uf && (liquidaBoletos || liquidaBoletos === 0)) {
            this.setState({ loading: validaImagensConfig ? false : true });
            const formData = new FormData();
            formData.append('emp_nomefantasia', nomeFantasia);
            formData.append('emp_razao', rzSocial);
            formData.append('emp_cnpj', cnpj);
            formData.append('emp_endlogradouro', logradouro);
            formData.append('emp_endnumero', numero);
            formData.append('emp_endcomplemento', complemento);
            formData.append('emp_endbairro', bairro);
            formData.append('emp_endcep', cep);
            formData.append('cid_id', cidId);
            formData.append('emp_ctabanco', banco);
            formData.append('emp_ctaagencia', agencia);
            formData.append('emp_ctanumero', conta);
            formData.append('emp_ctadigito', digito);
            formData.append('emp_telefone', telefone);
            formData.append('emp_emailcontato', emailContato);
            formData.append('emp_emailfinanceiro', emailFinanceiro);
            formData.append('emp_status', status);
            // formData.append('emp_token', token);
            // formData.append('ctad_id', idContaDigital);
            // formData.append('ctad_numero', numContaDigital);
            formData.append('emp_txcred1x', parseFloat(txCred1.replace(',', '.')));
            formData.append('emp_txcred2x', parseFloat(txCred2.replace(',', '.')));
            formData.append('emp_txcred3x', parseFloat(txCred3.replace(',', '.')));
            formData.append('emp_txcred4x', parseFloat(txCred4.replace(',', '.')));
            formData.append('emp_txcred5x', parseFloat(txCred5.replace(',', '.')));
            formData.append('emp_txcred6x', parseFloat(txCred6.replace(',', '.')));
            formData.append('emp_txcred7x', parseFloat(txCred7.replace(',', '.')));
            formData.append('emp_txcred8x', parseFloat(txCred8.replace(',', '.')));
            formData.append('emp_txcred9x', parseFloat(txCred9.replace(',', '.')));
            formData.append('emp_txcred10x', parseFloat(txCred10.replace(',', '.')));
            formData.append('emp_txcred11x', parseFloat(txCred11.replace(',', '.')));
            formData.append('emp_txcred12x', parseFloat(txCred12.replace(',', '.')));
            formData.append('emp_tarifapagto', parseFloat(tafPagto.replace(',', '.')));
            formData.append('emp_tarifadatainicio', Funcoes.data2Americano(dtInicioTarifa, "/"));
            formData.append('emp_tarifaliquidaboleto', parseFloat(tafLiq.replace(',', '.')));
            formData.append('emp_valorted', tafTed ? parseFloat(tafTed.replace(',', '.')) : '');
            formData.append('emp_tarifapagtocobranca', tipoCobranca);
            formData.append('emp_liquidaboleto', liquidaBoletos);
            formData.append('emp_habilitamsg', habilitaMsg);
            formData.append('emp_valormsg', valMsg ? parseFloat(valMsg.replace(',', '.')) : '');
            formData.append('emp_dtiniciocobmsg', dtInicioCobMsg ? Funcoes.data2Americano(dtInicioCobMsg, "/") : '');
            formData.append('emp_termosdeuso', termosUso);
            formData.append('emp_contrato', contrato);
            formData.append('emp_cartaoterceiro', cartao_terceiro);
            formData.append('ecfg_url', urlPortal);
            formData.append('ecfg_corprincipal', corPrincipal);
            formData.append('pmnu_id', menus.join(','));
            if (webhook)
                formData.append('emp_urlwebhook', webhook);
            if (tokenIntegracao)
                formData.append('emp_token', tokenIntegracao);
            if (validaImagensConfig) {
                if (imagemPerfil) {
                    formData.append('arquivo', imagemPerfil, imagemPerfil.name);
                } else {
                    this.setState({ alertText: '* O campo Logo Portal Parceiro não foi preenchido', alert: true });
                }
                if (logoTipo) {
                    formData.append('arquivo_config', logoTipo, logoTipo.name);
                } else {
                    this.setState({ alertText: '* O campo Logotipo do White Label não foi preenchido', alert: true });
                }
            } else {
                if (imagemPerfil)
                    formData.append('arquivo', imagemPerfil, imagemPerfil.name);
                if (logoTipo)
                    formData.append('arquivo_config', logoTipo, logoTipo.name);
            }
            if (imagemLogin)
                formData.append('arquivo_login', imagemLogin, imagemLogin.name);

            const userData = JSON.parse(sessionStorage.getItem('userData'));
            if (this.props.location.state) {
                this.setState({ loading: true });
                this.editarConta(formData, userData.token);
            } else {
                if (imagemPerfil && logoTipo) {
                    this.setState({ loading: true });
                    this.novaConta(formData, userData.token);
                }
            }
        }

        if (!status)
            this.setState({ alertText: '* O campo Status não foi preenchido', alert: true });

        if (!liquidaBoletos && liquidaBoletos !== 0)
            this.setState({ alertText: '* O campo Liquida Boleto não foi preenchido', alert: true });

        if (!valida.valido) {
            for (let item of valida.campos) {
                if (!item.valido) {
                    this.setState({ alertText: '* ' + item.mensagem, alert: true });
                    break;
                }
            }
        }
    }

    async novaConta(form, token) {
        await ApiService.postBackoffice('empresa', form, token)
            .then(res => {
                this.setState({ path: '/empresas', loading: false, redirect: true });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    async editarConta(form, token) {
        const { empresa } = this.props.location.state;
        await ApiService.customRequest('backoffice/empresa/alterar/' + empresa.id, 'POST', form, token)
            .then(res => {
                this.setState({ alertText: 'Empresa alterada com sucesso!', alertType: 1, loading: false, alert: true });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertType: 0, alertText: valida.response, alert: true });
            })
    }

    pegaArquivo(arquivo) {
        this.setState({ imagemPerfil: arquivo[0] });
    }

    pegaArqConfig(arquivo) {
        this.setState({ logoTipo: arquivo[0] });
    }

    pegaArqLogin(arquivo) {
        this.setState({ imagemLogin: arquivo[0] });
    }

    handleMenu(index, indexFilho) {
        const { arrMenus } = this.state;
        for (var i = 0; i < arrMenus.length; i++) {
            if (!arrMenus[i].pmnu_padrao) {
                if (indexFilho !== undefined) {
                    for (var x = 0; x < arrMenus[i].filhos.length; x++) {
                        if (i === index && x === indexFilho) {
                            arrMenus[i].filhos[x].checado = !arrMenus[i].filhos[x].checado
                        }
                    }
                } else {
                    if (i === index) {
                        arrMenus[i].checado = !arrMenus[i].checado
                    }
                }
            }
        }
        this.setState({ arrMenus: arrMenus });
    }

    render() {
        const { loading, redirect, path, alert, alertText, alertType, statusOptions, rzSocial, nomeFantasia, cnpj, telefone, emailContato,
            emailFinanceiro, status, cep, logradouro, numero, complemento, bairro, cidade, uf, banco, agencia, conta, digito, corPrincipal, urlPortal, tipoCobrancaOptions,
            tipoCobranca, dtInicioTarifa, simNaoOptions, liquidaBoletos, habilitaMsg, dtInicioCobMsg, termosUso, contrato, tokenIntegracao, webhook, copiado,
            arrMenus, modal, dataModal, cartao_terceiro } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} path={path} />
                <Alert show={alert} texto={alertText} tipo={alertType}
                    action={() => {
                        this.setState({ alert: false, redirect: path ? true : false })
                        if (alertType === 1) this.pegaEmpresaEditavel();
                    }} />
                <Loading show={loading} />
                <Modal show={modal}>
                    <p style={{ textAlign: 'right', marginBottom: 0, marginTop: 0, cursor: 'pointer' }} onClick={() => this.setState({ modal: false })}>x</p>
                    <h2>Carteira de Cobrança</h2>
                    <div style={{ textAlign: 'left', marginTop: 40 }}>
                        <p>Clientes Únicos: <b>{dataModal.totalClientes}</b></p>
                        <p>Total de Cobranças incluída: <b>{dataModal.totalCobrancas}</b></p>
                        <p>Cobranças Recebida: <b>{dataModal.cobrancasRecebidas}</b></p>
                        <p>Cobranças Baixadas: <b>{dataModal.cobrancasBaixadas}</b></p>
                        <p>Total Recebido: <b>R$ {dataModal.totalRecebido ? Funcoes.float2Preco(dataModal.totalRecebido) : Funcoes.float2Preco(0)}</b></p>
                    </div>
                </Modal>
                <SideMenu menuAtivo={2} />

                <div className="body">
                    <Header />

                    <div className="empresas-nova">
                        <div className="tituloButton">
                            <div>
                                <h1>{this.props.location.state ? 'Editar' : 'Nova'} Empresa</h1>
                                <p className="descricao">Parametrize abaixo a sua plataforma.</p>
                            </div>
                            {this.props.location.state ? (
                                <button className="btn bd-roxo cl-roxo" onClick={() => this.setState({ modal: true })}>Carteira de Cobrança</button>
                            ) : null}
                        </div>

                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Dados Cadastrais</h3>
                            </div>
                            <div className="panelBody">
                                <div className="multInput marginInputs" style={{ marginTop: 24 }}>
                                    <Input type="text" label="Razão Social" value={rzSocial} onChange={e => this.handleChange(e, 'rzSocial')} />
                                    <Input type="text" label="Nome Fantasia" value={nomeFantasia} onChange={e => this.handleChange(e, 'nomeFantasia')} />
                                    <Input type="tel" label="CNPJ" value={cnpj} style={{ marginRight: 0 }} maxLength="18" placeholder="00.000.000/0000-00"
                                        onChange={e => this.handleChange(e, 'cnpj', 'cnpj')} />
                                </div>
                                <div className="multInput">
                                    <Input type="tel" label="Telefone" value={telefone} maxLength="14" placeholder="(00) 00000-0000"
                                        onChange={e => this.handleChange(e, 'telefone', 'telefone')} />
                                    <Input type="text" label="E-mail Contato" value={emailContato} onChange={e => this.handleChange(e, 'emailContato')} />
                                    <Input type="text" label="E-mail Financeiro" value={emailFinanceiro}
                                        onChange={e => this.handleChange(e, 'emailFinanceiro')} />
                                    <Input type="select" label="Status" optionsData={statusOptions} style={{ marginRight: 0 }}
                                        value={status} onChange={e => this.handleChange(e, 'status')} />
                                </div>
                            </div>
                        </div>

                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Dados de Endereço</h3>
                            </div>
                            <div className="panelBody">
                                <div className="multInput marginInputs">
                                    <Input type="tel" label="CEP" value={cep} maxLength="9" onChange={e => this.handleChange(e, "cep", "cep")}
                                        placeholder="000000-000" />
                                    <Input type="text" label="Rua, Av.." value={logradouro} onChange={e => this.handleChange(e, 'logradouro')} />
                                    <Input type="text" label="Número" value={numero} maxLength="5" onChange={e => this.handleChange(e, 'numero')}
                                        style={{ marginRight: 0 }} id="numEnd" placeholder="00000" />
                                </div>
                                <div className="multInput">
                                    <Input type="text" label="Complemento" value={complemento}
                                        onChange={e => this.handleChange(e, 'complemento')} />
                                    <Input type="text" label="Bairro" value={bairro}
                                        onChange={e => this.handleChange(e, 'bairro')} />
                                    <Input type="text" label="Cidade" value={cidade}
                                        onChange={e => this.handleChange(e, 'cidade')} />
                                    <Input type="text" label="UF" value={uf} maxLength="2" disabled={true} className="disabled"
                                        onChange={e => this.handleChange(e, 'uf')} style={{ marginRight: 0 }} />
                                </div>
                            </div>
                        </div>

                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Dados Bancários</h3>
                            </div>
                            <div className="panelBody">
                                <div className="multInput">
                                    <Input label="Banco" grow={2} value={banco} onChange={e => this.handleChange(e, 'banco')} />
                                    <Input label="Agência" value={agencia} onChange={e => this.handleChange(e, 'agencia')} />
                                    <Input label="Conta" value={conta} onChange={e => this.handleChange(e, 'conta')} />
                                    <Input label="Dígito" grow={0} value={digito} onChange={e => this.handleChange(e, 'digito')} />
                                </div>
                            </div>
                        </div>

                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Configuração</h3>
                            </div>
                            <div className="panelBody">
                                {/* <div className="multInput marginInputs">
                                    <Input type="text" label="Token" value={token} onChange={e => this.handleChange(e, 'token')} />
                                    <Input type="tel" label="ID Conta Digital (EXOSS)" value={idContaDigital}
                                        onChange={e => this.handleChange(e, 'idContaDigital')} />
                                    <Input type="tel" label="Nº Conta Digital (EXOSS)" value={numContaDigital}
                                        onChange={e => this.handleChange(e, 'numContaDigital')} style={{ marginRight: 0 }} />
                                </div> */}
                                <div className="multInput txCred marginInputs">
                                    <Input type="tel" label="Tx. Cred. 1" grow={0} maxLength="5" id="txCred1" placeholder="0,00" />
                                    <Input type="tel" label="Tx. Cred. 2" grow={0} maxLength="5" id="txCred2" placeholder="0,00" />
                                    <Input type="tel" label="Tx. Cred. 3" grow={0} maxLength="5" id="txCred3" placeholder="0,00" />
                                    <Input type="tel" label="Tx. Cred. 4" grow={0} maxLength="5" id="txCred4" placeholder="0,00"
                                        style={{ marginRight: 0 }} />
                                </div>
                                <div className="multInput txCred marginInputs">
                                    <Input type="tel" label="Tx. Cred. 5" grow={0} maxLength="5" id="txCred5" placeholder="0,00" />
                                    <Input type="tel" label="Tx. Cred. 6" grow={0} maxLength="5" id="txCred6" placeholder="0,00" />
                                    <Input type="tel" label="Tx. Cred. 7" grow={0} maxLength="5" id="txCred7" placeholder="0,00" />
                                    <Input type="tel" label="Tx. Cred. 8" grow={0} maxLength="5" id="txCred8" placeholder="0,00"
                                        style={{ marginRight: 0 }} />
                                </div>
                                <div className="multInput txCred marginInputs">
                                    <Input type="tel" label="Tx. Cred. 9" grow={0} maxLength="5" id="txCred9" placeholder="0,00" />
                                    <Input type="tel" label="Tx. Cred. 10" grow={0} maxLength="5" id="txCred10" placeholder="0,00" />
                                    <Input type="tel" label="Tx. Cred. 11" grow={0} maxLength="5" id="txCred11" placeholder="0,00" />
                                    <Input type="tel" label="Tx. Cred. 12" grow={0} maxLength="5" id="txCred12" placeholder="0,00"
                                        style={{ marginRight: 0 }} />
                                </div>
                                <div className="multInput">
                                    <Input type="tel" label="Tarifa de TED" maxLength="5" id="tafTed" placeholder="0,00" divStyle={{ maxWidth: 150 }} />
                                    <Input type="tel" label="Tarifa de Pagto" maxLength="5" id="tafPagto" divStyle={{ maxWidth: 150 }} />
                                    <Input type="tel" label="Data Início Tarifa" maxLength="10" divStyle={{ maxWidth: 150 }}
                                        value={dtInicioTarifa} onChange={e => this.handleChange(e, 'dtInicioTarifa', 'data')} />
                                    <Input type="select" label="Tipo Cobrança Tarifa" optionsData={tipoCobrancaOptions}
                                        value={tipoCobranca} onChange={e => this.handleChange(e, 'tipoCobranca')} style={{ marginRight: 20 }} />
                                    <Input type="select" label="Liquida Boleto" optionsData={simNaoOptions}
                                        value={liquidaBoletos} onChange={e => this.handleChange(e, 'liquidaBoletos')} style={{ marginRight: 20 }} />
                                    <Input type="select" label="Cartão Terceiros" optionsData={simNaoOptions}
                                        value={cartao_terceiro} onChange={e => this.handleChange(e, 'cartao_terceiro')} style={{ marginRight: 20 }} />
                                    <Input type="tel" label="Tarifa Líquida Boleto" maxLength="5" id="tafLiq" divStyle={{ maxWidth: 150 }}
                                        style={{ marginRight: 0 }} />
                                </div>
                            </div>
                        </div>

                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Mensageria</h3>
                            </div>
                            <div className="panelBody">
                                <div className="multInput">
                                    <Input type="select" label="Habilita Mensagem" optionsData={simNaoOptions} value={habilitaMsg} onChange={e => this.handleChange(e, 'habilitaMsg')}
                                        divStyle={{ marginRight: 20 }} />
                                    <Input type="tel" label="Valor Mensagem" id="valMensagem" placeholder="0,00" />
                                    <Input type="mask" label="Dt Inicio Cobrança" mascara="99/99/9999" placeholder="00/00/0000" value={dtInicioCobMsg}
                                        onChange={e => this.handleChange(e, 'dtInicioCobMsg')} />
                                </div>
                                <Input type="textarea" label="Termos de Uso" value={termosUso} textEditor={true} attr="termosUso" changeEditor={this.handleChange} />
                                <Input type="textarea" label="Contrato" value={contrato} textEditor={true} attr="contrato" changeEditor={this.handleChange} divStyle={{ marginTop: 30 }} />
                            </div>
                        </div>

                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Integração</h3>
                            </div>
                            <div className="panelBody">
                                <div className="multInput">
                                    <Input type="text" label="Token (Gerado após o cadastro)" value={tokenIntegracao} onChange={e => this.handleChange(e, 'tokenIntegracao')} />
                                    <CopyToClipboard text={tokenIntegracao} onCopy={() => {
                                        this.setState({ copiado: true });
                                        setTimeout(() => {
                                            this.setState({ copiado: false });
                                        }, 3000);
                                    }}>
                                        <button>
                                            <img src={iconCopiar} alt="Copia token de integração" title="Copia token de integração" />
                                        </button>
                                    </CopyToClipboard>
                                </div>
                                <div className="multInput">
                                    <Input type="text" label="URL Webhook (confirmação de pagamento)" value={webhook} onChange={e => this.handleChange(e, 'webhook')}
                                        style={{ marginRight: 0 }} />
                                </div>
                                {copiado ? <span style={{ fontSize: 12, color: '#a93a3a', float: 'right', marginTop: -17 }}>Token copiado para área de transferência!</span> : null}
                            </div>
                        </div>

                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Personalização</h3>
                            </div>
                            <div className="panelBody" style={{ position: 'relative' }}>
                                {/* {mostraPicker ? (
                                    <div style={{ position: 'absolute', right: -43, bottom: 50 }}>
                                        <SketchPicker color={corPrincipal} onChange={color => this.setState({ corPrincipal: color.hex })}
                                            onChangeComplete={() => this.setState({ mostraPicker: false })} />
                                    </div>
                                ) : null} */}
                                <div className="multInput marginInputs">
                                    <Input type="file" label="Logo Portal Parceiro (100x100px)" pegaArquivo={this.pegaArquivo} className="arqId"
                                        accept=".png, .jpg, .jpeg" />
                                    <Input type="file" label="Imagem do Login (960x1060px)" pegaArquivo={this.pegaArqLogin} className="imgPersonalizada"
                                        multiFiles=".imgPersonalizada" accept=".png, .jpg, .jpeg" />
                                    <Input type="text" label="Cor Principal" value={corPrincipal} onChange={e => this.handleChange(e, 'corPrincipal')}
                                        style={{ marginRight: 0 }} placeholder="#000000" />
                                </div>
                                <div className="multInput">
                                    <Input type="file" label="Logotipo do White Label (Altura: 100px)" pegaArquivo={this.pegaArqConfig} className="arquivoConfig"
                                        multiFiles=".arquivoConfig" accept=".png, .jpg, .jpeg" />
                                    <Input type="text" label="Url Portal" value={urlPortal} onChange={e => this.handleChange(e, 'urlPortal')}
                                        style={{ marginRight: 0 }} />
                                </div>

                            </div>
                        </div>

                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Menus</h3>
                            </div>
                            <div className="panelBody menus">
                                {arrMenus.map((item, idx) => {
                                    return (
                                        <div className="menu" key={idx}>
                                            <label>
                                                <input type="checkbox" checked={item.pmnu_padrao ? true : item.checado} name="menu[]"
                                                    value={item.pmnu_id} onChange={e => this.handleMenu(idx)} />
                                                {item.pmnu_nome}
                                            </label>
                                            {item.filhos.map((child, index) => {
                                                return (
                                                    <div className="menu filho" style={{ marginLeft: 30 }} key={child.pmnu_id}>
                                                        <Input type="checkbox" label={child.pmnu_nome} checked={child.pmnu_padrao ? true : child.checado}
                                                            name="menu[]" value={child.pmnu_id} onChange={e => this.handleMenu(idx, index)} />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                        <div className="acoes">
                            <Link to="/empresas" className="btn">{'<'} Voltar</Link>
                            <button className="btn btnCadastrar bg-roxo cl-branco" onClick={() => this.validaCampos()}>{this.props.location.state ? 'Alterar' : 'Cadastrar'}</button>
                        </div>

                    </div>
                </div>
                <Footer />
            </Fragment >
        )
    }
}