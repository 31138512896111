import React, { Component, Fragment } from 'react';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Input from '../../Components/Input';
import { dataBrMask } from '../../Utils/mask';
import Funcoes from '../../Utils/functions';
import ApiService from '../../Utils/services';

export default class RemittanceGenerate extends Component {

    constructor() {
        super();

        this.state = {
            redirect: false,
            path: '',
            loading: false,
            alert: false,
            alertText: '',
            dtInicio: '',
            dtFim: ''
        };
    }

    async geraRemessa() {
        const { dtInicio, dtFim } = this.state;
        if (dtInicio && dtInicio.length === 10) {
            this.setState({ loading: true });
            const userData = JSON.parse(sessionStorage.getItem('userData'));

            var url = "remessa/gerar?dtpagto_inicial=" + Funcoes.data2Americano(dtInicio, '/');
            if (dtFim.length === 10) url = url + "&dtpagto_final=" + Funcoes.data2Americano(dtFim, '/')

            await ApiService.getBackoffice(url, userData.token)
                .then(response => {
                    const url = window.URL.createObjectURL(new Blob([response.data.texto]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', response.data.nome);
                    document.body.appendChild(link);
                    link.click();
                    this.setState({ loading: false });
                })
                .catch(err => {
                    const valida = ApiService.handleErros(err.response);
                    if (valida.status === "unauthorized") {
                        sessionStorage.removeItem('userData');
                        this.setState({ path: '/' });
                    }
                    if (valida.status === "serverError") { this.setState({ path: '' }); }
                    if (valida.status === "badRequest") { this.setState({ path: '' }); }
                    this.setState({ loading: false, alertText: valida.response, alert: true });
                })
        } else {
            this.setState({ alertText: 'O campo "Data Início" é obrigatório!', alert: true });
        }
    }

    b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || ''
        sliceSize = sliceSize || 512

        var byteCharacters = atob(b64Data)
        var byteArrays = []

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize)

            var byteNumbers = new Array(slice.length)
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i)
            }

            var byteArray = new Uint8Array(byteNumbers)

            byteArrays.push(byteArray)
        }

        var blob = new Blob(byteArrays, { type: contentType })
        let urlBlob = URL.createObjectURL(blob)
        return urlBlob
    }

    render() {
        const { redirect, path, loading, alert, alertText, dtInicio, dtFim } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} path={path} />
                <Loading show={loading} />
                <Alert show={alert} texto={alertText} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <SideMenu menuAtivo={7} />

                <div className="body">
                    <Header />

                    <div className="debitos">
                        <h1>Gerar Remessa</h1>
                        <p className="descricao">Gere abaixo uma nova remessa.</p>

                        <div className="filtros">
                            <div className="panel">
                                <Input type="tel" label="Data Início" grow={0} placeholder="00/00/0000" value={dtInicio} maxLength={10}
                                    style={{ width: 245, height: 40, marginRight: 20 }}
                                    onChange={e => this.setState({ dtInicio: dataBrMask(e.target.value) })} />
                                <Input type="tel" label="Data Fim" grow={0} placeholder="00/00/0000" value={dtFim} maxLength={10}
                                    style={{ width: 245, height: 40, marginRight: 20 }}
                                    onChange={e => this.setState({ dtFim: dataBrMask(e.target.value) })} />
                                <button className="btn bg-roxo cl-branco" onClick={() => this.geraRemessa()}>Gerar</button>
                                <a id="download-attach" download="filename" />
                            </div>
                        </div>

                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}