import React, { Component, Fragment } from 'react';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Input from '../../Components/Input';
import Alert from '../../Components/Alert';
import Loading from '../../Components/LoaderScreen';
import { validaForm } from '../../Utils/FormValidator';
import ApiService from '../../Utils/services';

export default class NewCompanyUser extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            redirect: false,
            path: '',
            propsToPath: '',
            alert: false,
            alertText: '',
            alertType: 0,
            statusOptions: [
                { value: '', text: 'Selecione' },
                { value: 1, text: 'Ativo' },
                { value: 2, text: 'Inativo' }
            ],
            perfisOptions: [{ value: '', text: 'Selecione' }],
            nomeEmpresa: props.location.state ? props.location.state.empresa.nome.toLowerCase() : 'Empresa',
            nome: '',
            status: '',
            perfilAcesso: '',
            email: '',
            senha: ''
        };
    }

    async componentDidMount() {
        if (!this.props.location.state) {
            this.setState({ path: '/empresas', redirect: true });
        } else {
            const { empresa, usuario } = this.props.location.state;
            const userData = JSON.parse(sessionStorage.getItem('userData'));

            await ApiService.getBackoffice('perfisacesso?emp_id=' + empresa.id, userData.token)
                .then(result => {
                    const res = result.data;
                    var perfis = [{ value: '', text: res.data.length > 0 ? 'Selecione' : 'Nenhum perfil foi encontrado!' }];
                    for (var i = 0; i < res.data.length; i++) {
                        const item = res.data[i];
                        perfis.push({ value: item.eperf_id, text: item.eperf_nome });
                    }
                    this.setState({ perfisOptions: perfis, loading: usuario ? true : false });
                })
                .catch(err => {
                    this.setState({ perfisOptions: [{ value: '', text: 'Nenhum perfil foi encontrado!' }], loading: usuario ? true : false });
                })

            if (usuario) {
                this.setState({ loading: true });
                await ApiService.getBackoffice('empresausuario/' + usuario.id, userData.token)
                    .then(result => {
                        const res = result.data.data;
                        this.setState({ nome: res.eusu_nome, email: res.eusu_email, status: res.eusu_status, perfilAcesso: res.eperf_id, loading: false });
                    })
                    .catch(err => {
                        this.setState({ path: '/empresas/usuarios', propsToPath: { empresa }, loading: false, redirect: true });
                    })
            }
        }
    }

    validaCampos() {
        const { nome, status, perfilAcesso, email, senha } = this.state;
        var form = [
            { campo: 'Nome', attr: 'nome', valor: nome },
            { campo: 'E-mail', attr: 'email', valor: email, tipo: 'email' },
            { campo: 'Perfil de Acesso', attr: 'perfilAcesso', valor: perfilAcesso }
        ];
        if (!this.props.location.state.usuario)
            form[2] = { campo: 'Senha', attr: 'senha', valor: senha, minLength: 5 }

        const valida = validaForm(form);
        if (valida.valido && status) {
            this.setState({ loading: true });
            var formData = {
                eusu_nome: nome,
                eusu_email: email,
                eusu_status: status,
                eperf_id: perfilAcesso,
                emp_id: this.props.location.state.empresa.id
            };
            if (!this.props.location.state.usuario) {
                formData = { ...formData, eusu_senha: senha };
            } else {
                if (senha) formData = { ...formData, eusu_senha: senha };
            }

            const userData = JSON.parse(sessionStorage.getItem('userData'));
            if (!this.props.location.state.usuario) {
                this.novaConta(formData, userData.token);
            } else {
                this.alteraConta(formData, userData.token)
            }
        }

        if (!status)
            this.setState({ alertText: '* O campo Status não foi preenchido', alert: true });

        if (!valida.valido) {
            for (let item of valida.campos) {
                if (!item.valido) {
                    this.setState({ alertText: '* ' + item.mensagem, alert: true });
                    break;
                }
            }
        }
    }

    async novaConta(form, token) {
        const { empresa } = this.props.location.state;
        await ApiService.postBackoffice('empresausuario', form, token)
            .then(res => {
                this.setState({ path: '/empresas/usuarios', propsToPath: { empresa: { ...empresa } }, loading: false, redirect: true });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    async alteraConta(form, token) {
        const { usuario } = this.props.location.state;
        await ApiService.customRequest('backoffice/empresausuario/' + usuario.id, 'PUT', form, token)
            .then(res => {
                this.setState({ alertType: 1, alertText: 'O usuário foi alterado com sucesso!', loading: false, alert: true });
                // this.setState({ path: '/empresas/usuarios', propsToPath: { empresa: { ...empresa } }, loading: false, redirect: true });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
        // pmnu_id
    }

    render() {
        const { redirect, path, propsToPath, alert, alertText, alertType, loading, statusOptions, perfisOptions, nomeEmpresa, nome, status, perfilAcesso, email, senha } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} props={propsToPath} path={path} />
                <Alert show={alert} texto={alertText} tipo={alertType} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <Loading show={loading} />
                <SideMenu menuAtivo={2} />

                <div className="body">
                    <Header />

                    <div className="empresas-usuarios-novo">
                        <h1 style={{ textTransform: 'capitalize' }}>{this.props.location.state.usuario ? 'Editar' : 'Novo'} Usuário - {nomeEmpresa}</h1>
                        <p className="descricao">Abaixo os dados do usuário da empresa selecionada</p>

                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Dados Básicos</h3>
                            </div>
                            <div className="panelBody">
                                <div className="multInput marginInputs" style={{ marginTop: 24 }}>
                                    <Input type="text" label="Nome" grow={4} value={nome} onChange={e => this.setState({ nome: e.target.value })} />
                                    <Input type="select" label="Status" optionsData={statusOptions} value={status} style={{ marginRight: 27 }}
                                        onChange={e => this.setState({ status: e.target.value })} />
                                    <Input type="select" label="Perfil de Acesso" optionsData={perfisOptions} value={perfilAcesso}
                                        onChange={e => this.setState({ perfilAcesso: e.target.value })} />
                                </div>
                            </div>
                        </div>

                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Dados de Acesso</h3>
                            </div>
                            <div className="panelBody">
                                <div className="multInput">
                                    <Input type="text" label="E-mail" value={email} onChange={e => this.setState({ email: e.target.value })} />
                                    <Input type="password" label="Senha" value={senha} onChange={e => this.setState({ senha: e.target.value })} style={{ marginRight: 0 }} />
                                </div>
                            </div>
                        </div>
                        <div className="acoes">
                            <button className="btn voltar" onClick={() => this.setState({ path: '/empresas/usuarios', propsToPath: { ...this.props.location.state }, redirect: true })}>{'<'} Voltar</button>
                            <button className="btn btnCadastrar bg-roxo cl-branco" onClick={() => this.validaCampos()}>
                                {this.props.location.state.usuario ? 'Alterar' : 'Cadastrar'}
                            </button>
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}